define('ember-css-modules/index', ['exports', 'ember-css-modules/decorators'], function (exports, _decorators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.keys(_decorators).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _decorators[key];
      }
    });
  });
});