define('ember-data-github/serializers/github', ['exports', 'ember-inflector', 'ember-data'], function (exports, _emberInflector, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _emberData.default.RESTSerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.decamelize(attr);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.recordId = id;
      var wrappedPayload = {};
      var fieldName = primaryModelClass.modelName;
      if (Ember.isArray(payload)) {
        fieldName = (0, _emberInflector.pluralize)(fieldName);
      }
      wrappedPayload[fieldName] = payload;
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },


    // Add metadata to the response for use with pagination. Formatted like:
    //   {
    //     first: { page: 1, per_page: 5 }
    //     next:  { page: 3, per_page: 5 },
    //     prev:  { page: 1, per_page: 5 },
    //     last:  { page: 3, per_page: 5 }
    //   }
    //
    extractMeta: function extractMeta(store, modelClass, payload) {
      var links = Ember.get(payload, (0, _emberInflector.pluralize)(modelClass.modelName) + '.links');

      if (Ember.isNone(links)) {
        return;
      }

      return Object.keys(links).reduce(function (meta, name) {
        var link = links[name];
        var qs = link.split('?').pop();

        meta[name] = qs.split('&').reduce(function (memo, str) {
          var _str$split = str.split('='),
              _str$split2 = _slicedToArray(_str$split, 2),
              key = _str$split2[0],
              value = _str$split2[1];

          memo[key] = parseInt(value, 10);
          return memo;
        }, {});

        return meta;
      }, {});
    }
  });
});