define('ember-data-github/serializers/github-compare', ['exports', 'ember-data', 'ember-data-github/serializers/github'], function (exports, _emberData, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _github.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      commits: { embedded: 'always' },
      files: { embedded: 'always' },
      baseCommit: { embedded: 'always' },
      mergeBaseCommit: { embedded: 'always' }
    },

    normalize: function normalize(modelClass, resourceHash, prop) {
      resourceHash.id = resourceHash.diff_url;

      return this._super(modelClass, resourceHash, prop);
    },
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      resourceHash.base_commit.type = 'github-commit';
      resourceHash.base_commit.id = resourceHash['base_commit']['sha'];

      resourceHash.merge_base_commit.type = 'github-commit';
      resourceHash.merge_base_commit.id = resourceHash['merge_base_commit']['sha'];

      resourceHash.commits.forEach(function (commit) {
        commit.type = 'github-commit';
        commit.id = commit.sha;
      });

      resourceHash.files.forEach(function (file) {
        file.type = 'github-file';
        file.id = file.sha;
      });

      return this._super(modelClass, resourceHash);
    }
  });
});