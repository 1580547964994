enifed('@glimmer/encoder', ['exports'], function (exports) {
    'use strict';

    exports.InstructionEncoder = undefined;

    var InstructionEncoder = function () {
        function InstructionEncoder(buffer) {

            this.buffer = buffer;
            this.typePos = 0;
            this.size = 0;
        }

        InstructionEncoder.prototype.encode = function (type, machine) {
            var i, op;

            if (type > 255 /* TYPE_SIZE */) {
                    throw new Error('Opcode type over 8-bits. Got ' + type + '.');
                }
            this.buffer.push(type | machine | arguments.length - 2 << 8 /* ARG_SHIFT */);
            this.typePos = this.buffer.length - 1;
            for (i = 2; i < arguments.length; i++) {
                op = arguments[i];

                if (typeof op === 'number' && op > 65535 /* MAX_SIZE */) {
                        throw new Error('Operand over 16-bits. Got ' + op + '.');
                    }
                this.buffer.push(op);
            }

            this.size = this.buffer.length;
        };

        InstructionEncoder.prototype.patch = function (position, operand) {
            if (this.buffer[position + 1] === -1) {
                this.buffer[position + 1] = operand;
            } else {
                throw new Error('Trying to patch operand in populated slot instead of a reserved slot.');
            }
        };

        return InstructionEncoder;
    }();

    exports.InstructionEncoder = InstructionEncoder;
});