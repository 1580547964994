define('ember-is-fastboot/mixins/is-fastboot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _fastboot: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),

    _isFastBoot: Ember.computed.readOnly('_fastboot.isFastBoot')
  });
});