enifed('ember-runtime/system/namespace', ['exports', 'ember-utils', 'ember-metal', 'ember-environment', 'ember-runtime/system/object'], function (exports, _emberUtils, _emberMetal, _emberEnvironment, _object) {
  'use strict';

  exports.isSearchDisabled = // Preloaded into namespaces
  /**
  @module ember
  */
  function () {
    return searchDisabled;
  };
  exports.setSearchDisabled = function (flag) {
    searchDisabled = !!flag;
  }

  /**
    A Namespace is an object usually used to contain other objects or methods
    such as an application or framework. Create a namespace anytime you want
    to define one of these new containers.
  
    # Example Usage
  
    ```javascript
    MyFramework = Ember.Namespace.create({
      VERSION: '1.0.0'
    });
    ```
  
    @class Namespace
    @namespace Ember
    @extends EmberObject
    @public
  */
  ;

  var searchDisabled = false;var Namespace = _object.default.extend({
    isNamespace: true,

    init: function () {
      Namespace.NAMESPACES.push(this);
      Namespace.PROCESSED = false;
    },
    toString: function () {
      var name = (0, _emberMetal.get)(this, 'name') || (0, _emberMetal.get)(this, 'modulePrefix');
      if (name) {
        return name;
      }

      findNamespaces();
      return this[_emberUtils.NAME_KEY];
    },
    nameClasses: function () {
      processNamespace([this.toString()], this, {});
    },
    destroy: function () {
      var namespaces = Namespace.NAMESPACES;
      var toString = this.toString();

      if (toString) {
        _emberEnvironment.context.lookup[toString] = undefined;
        delete Namespace.NAMESPACES_BY_ID[toString];
      }
      namespaces.splice(namespaces.indexOf(this), 1);
      this._super.apply(this, arguments);
    }
  });

  Namespace.reopenClass({
    NAMESPACES: [],
    NAMESPACES_BY_ID: {},
    PROCESSED: false,
    processAll: processAllNamespaces,
    byName: function (name) {
      if (!searchDisabled) {
        processAllNamespaces();
      }

      return NAMESPACES_BY_ID[name];
    }
  });

  var NAMESPACES_BY_ID = Namespace.NAMESPACES_BY_ID;

  var hasOwnProp = {}.hasOwnProperty;

  function processNamespace(paths, root, seen) {
    var idx = paths.length,
        obj;

    NAMESPACES_BY_ID[paths.join('.')] = root;

    // Loop over all of the keys in the namespace, looking for classes
    for (var key in root) {
      if (!hasOwnProp.call(root, key)) {
        continue;
      }
      obj = root[key];

      // If we are processing the `Ember` namespace, for example, the
      // `paths` will start with `["Ember"]`. Every iteration through
      // the loop will update the **second** element of this list with
      // the key, so processing `Ember.View` will make the Array
      // `['Ember', 'View']`.

      paths[idx] = key;

      // If we have found an unprocessed class
      if (obj && obj.toString === classToString && !obj[_emberUtils.NAME_KEY]) {
        // Replace the class' `toString` with the dot-separated path
        // and set its `NAME_KEY`
        obj[_emberUtils.NAME_KEY] = paths.join('.');

        // Support nested namespaces
      } else if (obj && obj.isNamespace) {
        // Skip aliased namespaces
        if (seen[(0, _emberUtils.guidFor)(obj)]) {
          continue;
        }
        seen[(0, _emberUtils.guidFor)(obj)] = true;

        // Process the child namespace
        processNamespace(paths, obj, seen);
      }
    }

    paths.length = idx; // cut out last item
  }

  function isUppercase(code) {
    return code >= 65 && // A
    code <= 90; // Z
  }

  function tryIsNamespace(lookup, prop) {
    var obj;

    try {
      obj = lookup[prop];

      return obj && obj.isNamespace && obj;
    } catch (e) {
      // continue
    }
  }

  function findNamespaces() {
    if (Namespace.PROCESSED) {
      return;
    }
    var lookup = _emberEnvironment.context.lookup,
        i,
        key,
        obj;
    var keys = Object.keys(lookup);
    for (i = 0; i < keys.length; i++) {
      key = keys[i];
      // Only process entities that start with uppercase A-Z

      if (!isUppercase(key.charCodeAt(0))) {
        continue;
      }
      obj = tryIsNamespace(lookup, key);

      if (obj) {
        obj[_emberUtils.NAME_KEY] = key;
      }
    }
  }

  function superClassString(mixin) {
    var superclass = mixin.superclass;
    if (superclass) {
      if (superclass[_emberUtils.NAME_KEY]) {
        return superclass[_emberUtils.NAME_KEY];
      }
      return superClassString(superclass);
    }
  }

  function calculateToString(target) {
    var str = void 0;

    if (!searchDisabled) {
      processAllNamespaces();
      // can also be set by processAllNamespaces
      str = target[_emberUtils.NAME_KEY];
      if (str) {
        return str;
      } else {
        str = superClassString(target);
        str = str ? '(subclass of ' + str + ')' : str;
      }
    }
    if (str) {
      return str;
    } else {
      return '(unknown mixin)';
    }
  }

  function classToString() {
    var name = this[_emberUtils.NAME_KEY];
    if (name) {
      return name;
    }

    return this[_emberUtils.NAME_KEY] = calculateToString(this);
  }

  function processAllNamespaces() {
    var unprocessedNamespaces = !Namespace.PROCESSED,
        namespaces,
        namespace,
        i;
    var unprocessedMixins = (0, _emberMetal.hasUnprocessedMixins)();

    if (unprocessedNamespaces) {
      findNamespaces();
      Namespace.PROCESSED = true;
    }

    if (unprocessedNamespaces || unprocessedMixins) {
      namespaces = Namespace.NAMESPACES;
      namespace = void 0;


      for (i = 0; i < namespaces.length; i++) {
        namespace = namespaces[i];
        processNamespace([namespace.toString()], namespace, {});
      }

      (0, _emberMetal.clearUnprocessedMixins)();
    }
  }

  _emberMetal.Mixin.prototype.toString = classToString; // ES6TODO: altering imported objects. SBB.

  exports.default = Namespace;
});