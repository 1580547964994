enifed('@glimmer/opcode-compiler', ['exports', 'ember-babel', '@glimmer/util', '@glimmer/vm', '@glimmer/wire-format', '@glimmer/encoder'], function (exports, _emberBabel, _util, _vm, _wireFormat, _encoder) {
    'use strict';

    exports.PLACEHOLDER_HANDLE = exports.WrappedBuilder = exports.logOpcode = exports.debugSlice = exports.debug = exports.CompilableTemplate = exports.templateFactory = exports.PartialDefinition = exports.SimpleOpcodeBuilder = exports.OpcodeBuilder = exports.EagerOpcodeBuilder = exports.LazyOpcodeBuilder = exports.Macros = exports.ATTRS_BLOCK = undefined;

    var Ops$2;
    (function (Ops$$1) {
        Ops$$1[Ops$$1["OpenComponentElement"] = 0] = "OpenComponentElement";
        Ops$$1[Ops$$1["DidCreateElement"] = 1] = "DidCreateElement";
        Ops$$1[Ops$$1["SetComponentAttrs"] = 2] = "SetComponentAttrs";
        Ops$$1[Ops$$1["DidRenderLayout"] = 3] = "DidRenderLayout";
        Ops$$1[Ops$$1["Debugger"] = 4] = "Debugger";
    })(Ops$2 || (Ops$2 = {}));

    var Ops$1 = _wireFormat.Ops;
    var ATTRS_BLOCK = '&attrs';

    var Compilers = function () {
        function Compilers() {
            var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;


            this.offset = offset;
            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Compilers.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Compilers.prototype.compile = function (sexp, builder) {
            var name = sexp[this.offset];
            var index = this.names[name];
            var func = this.funcs[index];
            func(sexp, builder);
        };

        return Compilers;
    }();

    var _statementCompiler = void 0;
    function statementCompiler() {
        if (_statementCompiler) {
            return _statementCompiler;
        }
        var STATEMENTS = _statementCompiler = new Compilers();
        STATEMENTS.add(Ops$1.Text, function (sexp, builder) {
            builder.text(sexp[1]);
        });
        STATEMENTS.add(Ops$1.Comment, function (sexp, builder) {
            builder.comment(sexp[1]);
        });
        STATEMENTS.add(Ops$1.CloseElement, function (_sexp, builder) {
            builder.closeElement();
        });
        STATEMENTS.add(Ops$1.FlushElement, function (_sexp, builder) {
            builder.flushElement();
        });
        STATEMENTS.add(Ops$1.Modifier, function (sexp, builder) {
            var resolver = builder.resolver,
                referrer = builder.referrer;
            var name = sexp[1],
                params = sexp[2],
                hash = sexp[3];

            var handle = resolver.lookupModifier(name, referrer);
            if (handle) {
                builder.modifier(handle, params, hash);
            } else {
                throw new Error('Compile Error ' + name + ' is not a modifier: Helpers may not be used in the element form.');
            }
        });
        STATEMENTS.add(Ops$1.StaticAttr, function (sexp, builder) {
            var name = sexp[1],
                value = sexp[2],
                namespace = sexp[3];

            builder.staticAttr(name, namespace, value);
        });
        STATEMENTS.add(Ops$1.DynamicAttr, function (sexp, builder) {
            dynamicAttr(sexp, false, builder);
        });
        STATEMENTS.add(Ops$1.TrustingAttr, function (sexp, builder) {
            dynamicAttr(sexp, true, builder);
        });
        STATEMENTS.add(Ops$1.OpenElement, function (sexp, builder) {
            builder.openPrimitiveElement(sexp[1]);
        });
        STATEMENTS.add(Ops$1.OpenSplattedElement, function (sexp, builder) {
            builder.setComponentAttrs(true);
            builder.putComponentOperations();
            builder.openPrimitiveElement(sexp[1]);
        });
        STATEMENTS.add(Ops$1.Component, function (sexp, builder) {
            var tag = sexp[1],
                _attrs = sexp[2],
                args = sexp[3],
                block = sexp[4],
                capabilities,
                attrs,
                attrsBlock,
                child,
                layout;
            var resolver = builder.resolver,
                referrer = builder.referrer;

            var handle = resolver.lookupComponentDefinition(tag, referrer);
            if (handle !== null) {
                capabilities = resolver.getCapabilities(handle);
                attrs = [[Ops$1.ClientSideStatement, Ops$2.SetComponentAttrs, true]].concat(_attrs, [[Ops$1.ClientSideStatement, Ops$2.SetComponentAttrs, false]]);
                attrsBlock = builder.inlineBlock({ statements: attrs, parameters: _util.EMPTY_ARRAY });
                child = builder.template(block);

                if (capabilities.dynamicLayout === false) {
                    layout = resolver.getLayout(handle);

                    builder.pushComponentDefinition(handle);
                    builder.invokeStaticComponent(capabilities, layout, attrsBlock, null, args, false, child && child);
                } else {
                    builder.pushComponentDefinition(handle);
                    builder.invokeComponent(attrsBlock, null, args, false, child && child);
                }
            } else {
                throw new Error('Compile Error: Cannot find component ' + tag);
            }
        });
        STATEMENTS.add(Ops$1.Partial, function (sexp, builder) {
            var name = sexp[1],
                evalInfo = sexp[2];
            var referrer = builder.referrer;

            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            builder.expr(name);
            builder.dup();
            builder.enter(2);
            builder.jumpUnless('ELSE');
            builder.invokePartial(referrer, builder.evalSymbols(), evalInfo);
            builder.popScope();
            builder.popFrame();
            builder.label('ELSE');
            builder.exit();
            builder.return();
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        STATEMENTS.add(Ops$1.Yield, function (sexp, builder) {
            var to = sexp[1],
                params = sexp[2];

            builder.yield(to, params);
        });
        STATEMENTS.add(Ops$1.AttrSplat, function (sexp, builder) {
            var to = sexp[1];

            builder.yield(to, []);
            builder.didCreateElement(_vm.Register.s0);
            builder.setComponentAttrs(false);
        });
        STATEMENTS.add(Ops$1.Debugger, function (sexp, builder) {
            var evalInfo = sexp[1];

            builder.debugger(builder.evalSymbols(), evalInfo);
        });
        STATEMENTS.add(Ops$1.ClientSideStatement, function (sexp, builder) {
            CLIENT_SIDE.compile(sexp, builder);
        });
        STATEMENTS.add(Ops$1.Append, function (sexp, builder) {
            var value = sexp[1],
                trusting = sexp[2];
            var inlines = builder.macros.inlines;

            var returned = inlines.compile(sexp, builder) || value;
            if (returned === true) return;
            var isGet$$1 = (0, _wireFormat.isGet)(value);
            var isMaybeLocal$$1 = (0, _wireFormat.isMaybeLocal)(value);
            if (trusting) {
                builder.guardedAppend(value, true);
            } else {
                if (isGet$$1 || isMaybeLocal$$1) {
                    builder.guardedAppend(value, false);
                } else {
                    builder.expr(value);
                    builder.primitive(false);
                    builder.load(_vm.Register.t0);
                    builder.dynamicContent();
                }
            }
        });
        STATEMENTS.add(Ops$1.Block, function (sexp, builder) {
            var name = sexp[1],
                params = sexp[2],
                hash = sexp[3],
                _template = sexp[4],
                _inverse = sexp[5];

            var template = builder.template(_template);
            var inverse = builder.template(_inverse);

            var blocks = builder.macros.blocks;

            blocks.compile(name, params, hash, template && template, inverse && inverse, builder);
        });
        var CLIENT_SIDE = new Compilers(1);
        CLIENT_SIDE.add(Ops$2.OpenComponentElement, function (sexp, builder) {
            builder.putComponentOperations();
            builder.openPrimitiveElement(sexp[2]);
        });
        CLIENT_SIDE.add(Ops$2.DidCreateElement, function (_sexp, builder) {
            builder.didCreateElement(_vm.Register.s0);
        });
        CLIENT_SIDE.add(Ops$2.SetComponentAttrs, function (sexp, builder) {
            builder.setComponentAttrs(sexp[2]);
        });
        CLIENT_SIDE.add(Ops$2.Debugger, function () {
            // tslint:disable-next-line:no-debugger
            debugger;
        });
        CLIENT_SIDE.add(Ops$2.DidRenderLayout, function (_sexp, builder) {
            builder.didRenderLayout(_vm.Register.s0);
        });
        return STATEMENTS;
    }
    function dynamicAttr(sexp, trusting, builder) {
        var name = sexp[1],
            value = sexp[2],
            namespace = sexp[3];

        builder.expr(value);
        if (namespace) {
            builder.dynamicAttr(name, namespace, trusting);
        } else {
            builder.dynamicAttr(name, null, trusting);
        }
    }
    var _expressionCompiler = void 0;
    function expressionCompiler() {
        if (_expressionCompiler) {
            return _expressionCompiler;
        }
        var EXPRESSIONS = _expressionCompiler = new Compilers();
        EXPRESSIONS.add(Ops$1.Unknown, function (sexp, builder) {
            var resolver = builder.resolver,
                asPartial = builder.asPartial,
                referrer = builder.referrer;

            var name = sexp[1];
            var handle = resolver.lookupHelper(name, referrer);
            if (handle !== null) {
                builder.helper(handle, null, null);
            } else if (asPartial) {
                builder.resolveMaybeLocal(name);
            } else {
                builder.getVariable(0);
                builder.getProperty(name);
            }
        });
        EXPRESSIONS.add(Ops$1.Concat, function (sexp, builder) {
            var parts = sexp[1],
                i;
            for (i = 0; i < parts.length; i++) {
                builder.expr(parts[i]);
            }
            builder.concat(parts.length);
        });
        EXPRESSIONS.add(Ops$1.Helper, function (sexp, builder) {
            var resolver = builder.resolver,
                referrer = builder.referrer,
                definition,
                restArgs;
            var name = sexp[1],
                params = sexp[2],
                hash = sexp[3];

            // TODO: triage this in the WF compiler
            if (name === 'component') {
                definition = params[0], restArgs = params.slice(1);


                builder.curryComponent(definition, restArgs, hash, true);
                return;
            }
            var handle = resolver.lookupHelper(name, referrer);
            if (handle !== null) {
                builder.helper(handle, params, hash);
            } else {
                throw new Error('Compile Error: ' + name + ' is not a helper');
            }
        });
        EXPRESSIONS.add(Ops$1.Get, function (sexp, builder) {
            var head = sexp[1],
                path = sexp[2],
                i;

            builder.getVariable(head);
            for (i = 0; i < path.length; i++) {
                builder.getProperty(path[i]);
            }
        });
        EXPRESSIONS.add(Ops$1.MaybeLocal, function (sexp, builder) {
            var path = sexp[1],
                head,
                i;

            if (builder.asPartial) {
                head = path[0];

                path = path.slice(1);
                builder.resolveMaybeLocal(head);
            } else {
                builder.getVariable(0);
            }
            for (i = 0; i < path.length; i++) {
                builder.getProperty(path[i]);
            }
        });
        EXPRESSIONS.add(Ops$1.Undefined, function (_sexp, builder) {
            return builder.pushPrimitiveReference(undefined);
        });
        EXPRESSIONS.add(Ops$1.HasBlock, function (sexp, builder) {
            builder.hasBlock(sexp[1]);
        });
        EXPRESSIONS.add(Ops$1.HasBlockParams, function (sexp, builder) {
            builder.hasBlockParams(sexp[1]);
        });
        return EXPRESSIONS;
    }

    var Blocks = function () {
        function Blocks() {

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Blocks.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Blocks.prototype.addMissing = function (func) {
            this.missing = func;
        };

        Blocks.prototype.compile = function (name, params, hash, template, inverse, builder) {
            var index = this.names[name],
                func,
                _func;
            if (index === undefined) {
                func = this.missing;
                func(name, params, hash, template, inverse, builder);
            } else {
                _func = this.funcs[index];

                _func(params, hash, template, inverse, builder);
            }
        };

        return Blocks;
    }();

    var Inlines = function () {
        function Inlines() {

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Inlines.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Inlines.prototype.addMissing = function (func) {
            this.missing = func;
        };

        Inlines.prototype.compile = function (sexp, builder) {
            var value = sexp[1],
                func,
                returned,
                _func2,
                _returned;
            // TODO: Fix this so that expression macros can return
            // things like components, so that {{component foo}}
            // is the same as {{(component foo)}}
            if (!Array.isArray(value)) return ['expr', value];
            var name = void 0;
            var params = void 0;
            var hash = void 0;
            if (value[0] === Ops$1.Helper) {
                name = value[1];
                params = value[2];
                hash = value[3];
            } else if (value[0] === Ops$1.Unknown) {
                name = value[1];
                params = hash = null;
            } else {
                return ['expr', value];
            }
            var index = this.names[name];
            if (index === undefined && this.missing) {
                func = this.missing;
                returned = func(name, params, hash, builder);

                return returned === false ? ['expr', value] : returned;
            } else if (index !== undefined) {
                _func2 = this.funcs[index];
                _returned = _func2(name, params, hash, builder);

                return _returned === false ? ['expr', value] : _returned;
            } else {
                return ['expr', value];
            }
        };

        return Inlines;
    }();

    function populateBuiltins() {
        var blocks = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Blocks();
        var inlines = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Inlines();

        blocks.add('if', function (params, _hash, template, inverse, builder) {
            //        PutArgs
            //        Test(Environment)
            //        Enter(BEGIN, END)
            // BEGIN: Noop
            //        JumpUnless(ELSE)
            //        Evaluate(default)
            //        Jump(END)
            // ELSE:  Noop
            //        Evalulate(inverse)
            // END:   Noop
            //        Exit
            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #if requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            builder.expr(params[0]);
            builder.toBoolean();
            builder.enter(1);
            builder.jumpUnless('ELSE');
            builder.invokeStaticBlock(template);
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStaticBlock(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('unless', function (params, _hash, template, inverse, builder) {
            //        PutArgs
            //        Test(Environment)
            //        Enter(BEGIN, END)
            // BEGIN: Noop
            //        JumpUnless(ELSE)
            //        Evaluate(default)
            //        Jump(END)
            // ELSE:  Noop
            //        Evalulate(inverse)
            // END:   Noop
            //        Exit
            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #unless requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            builder.expr(params[0]);
            builder.toBoolean();
            builder.enter(1);
            builder.jumpIf('ELSE');
            builder.invokeStaticBlock(template);
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStaticBlock(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('with', function (params, _hash, template, inverse, builder) {
            //        PutArgs
            //        Test(Environment)
            //        Enter(BEGIN, END)
            // BEGIN: Noop
            //        JumpUnless(ELSE)
            //        Evaluate(default)
            //        Jump(END)
            // ELSE:  Noop
            //        Evalulate(inverse)
            // END:   Noop
            //        Exit
            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #with requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            builder.expr(params[0]);
            builder.dup();
            builder.toBoolean();
            builder.enter(2);
            builder.jumpUnless('ELSE');
            builder.invokeStaticBlock(template, 1);
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStaticBlock(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('each', function (params, hash, template, inverse, builder) {
            //         Enter(BEGIN, END)
            // BEGIN:  Noop
            //         PutArgs
            //         PutIterable
            //         JumpUnless(ELSE)
            //         EnterList(BEGIN2, END2)
            // ITER:   Noop
            //         NextIter(BREAK)
            // BEGIN2: Noop
            //         PushChildScope
            //         Evaluate(default)
            //         PopScope
            // END2:   Noop
            //         Exit
            //         Jump(ITER)
            // BREAK:  Noop
            //         ExitList
            //         Jump(END)
            // ELSE:   Noop
            //         Evalulate(inverse)
            // END:    Noop
            //         Exit
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            if (hash && hash[0][0] === 'key') {
                builder.expr(hash[1][0]);
            } else {
                builder.pushPrimitiveReference(null);
            }
            builder.expr(params[0]);
            builder.enter(2);
            builder.putIterator();
            builder.jumpUnless('ELSE');
            builder.pushFrame();
            builder.returnTo('ITER');
            builder.dup(_vm.Register.fp, 1);
            builder.enterList('BODY');
            builder.label('ITER');
            builder.iterate('BREAK');
            builder.label('BODY');
            builder.invokeStaticBlock(template, 2);
            builder.pop(2);
            builder.exit();
            builder.return();
            builder.label('BREAK');
            builder.exitList();
            builder.popFrame();
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStaticBlock(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('in-element', function (params, hash, template, _inverse, builder) {
            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #in-element requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            var keys = hash[0],
                values = hash[1],
                i,
                key;

            for (i = 0; i < keys.length; i++) {
                key = keys[i];

                if (key === 'nextSibling' || key === 'guid') {
                    builder.expr(values[i]);
                } else {
                    throw new Error('SYNTAX ERROR: #in-element does not take a `' + keys[0] + '` option');
                }
            }
            builder.expr(params[0]);
            builder.dup();
            builder.enter(4);
            builder.jumpUnless('ELSE');
            builder.pushRemoteElement();
            builder.invokeStaticBlock(template);
            builder.popRemoteElement();
            builder.label('ELSE');
            builder.exit();
            builder.return();
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('-with-dynamic-vars', function (_params, hash, template, _inverse, builder) {
            var names, expressions;

            if (hash) {
                names = hash[0], expressions = hash[1];


                builder.compileParams(expressions);
                builder.pushDynamicScope();
                builder.bindDynamicScope(names);
                builder.invokeStaticBlock(template);
                builder.popDynamicScope();
            } else {
                builder.invokeStaticBlock(template);
            }
        });
        blocks.add('component', function (_params, hash, template, inverse, builder) {
            var tag = _params[0],
                returned;
            if (typeof tag === 'string') {
                returned = builder.staticComponentHelper(_params[0], hash, template);

                if (returned) return;
            }

            var definition = _params[0],
                params = _params.slice(1);

            builder.dynamicComponent(definition, params, hash, true, template, inverse);
        });
        inlines.add('component', function (_name, _params, hash, builder) {
            var tag = _params && _params[0],
                returned;
            if (typeof tag === 'string') {
                returned = builder.staticComponentHelper(tag, hash, null);

                if (returned) return true;
            }

            var definition = _params[0],
                params = _params.slice(1);

            builder.dynamicComponent(definition, params, hash, true, null, null);
            return true;
        });
        return { blocks: blocks, inlines: inlines };
    }

    function json() {}

    function decodePrimitive(primitive, constants) {
        // 111
        var value = primitive >> 3;
        switch (primitive & 7) {
            case 0 /* NUMBER */:
                return value;
            case 1 /* FLOAT */:
                return constants.getNumber(value);
            case 2 /* STRING */:
                return constants.getString(value);
            case 3 /* BOOLEAN_OR_VOID */:
                switch (value) {
                    case 0:
                        return false;
                    case 1:
                        return true;
                    case 2:
                        return null;
                    case 3:
                        return undefined;
                }
            case 4 /* NEGATIVE */:
                return constants.getNumber(value);
            default:
                throw (0, _util.unreachable)();
        }
    }

    var PLACEHOLDER_HANDLE$1 = -1;

    var CompilableTemplateImpl = function () {
        function CompilableTemplateImpl(statements, containingLayout, options, symbolTable) {

            this.statements = statements;
            this.containingLayout = containingLayout;
            this.options = options;
            this.symbolTable = symbolTable;
            this.compiled = null;
            this.statementCompiler = statementCompiler();
        }

        CompilableTemplateImpl.topLevel = function (block, options) {
            return new CompilableTemplateImpl(block.statements, { block: block, referrer: options.referrer }, options, { hasEval: block.hasEval, symbols: block.symbols });
        };

        CompilableTemplateImpl.prototype.compile = function (stdLib) {
            var compiled = this.compiled,
                i;

            if (compiled !== null) return compiled;
            // Track that compilation has started but not yet finished by temporarily
            // using a placeholder handle. In eager compilation mode, where compile()
            // may be called recursively, we use this as a signal that the handle cannot
            // be known synchronously and must be linked lazily.
            this.compiled = PLACEHOLDER_HANDLE$1;
            var options = this.options,
                statements = this.statements,
                containingLayout = this.containingLayout;
            var referrer = containingLayout.referrer;
            var program = options.program,
                resolver = options.resolver,
                macros = options.macros,
                asPartial = options.asPartial,
                Builder = options.Builder;

            var builder = new Builder(program, resolver, referrer, macros, containingLayout, asPartial, stdLib);
            for (i = 0; i < statements.length; i++) {
                this.statementCompiler.compile(statements[i], builder);
            }
            var handle = builder.commit(program.heap, containingLayout.block.symbols.length);
            return this.compiled = handle;
        };

        return CompilableTemplateImpl;
    }();

    var WrappedBuilder = function () {
        function WrappedBuilder(options, layout) {

            this.options = options;
            this.layout = layout;
            var block = layout.block;

            this.symbolTable = {
                hasEval: block.hasEval,
                symbols: block.symbols.concat([ATTRS_BLOCK])
            };
        }

        WrappedBuilder.prototype.compile = function () {
            var options = this.options,
                layout = this.layout,
                referrer = this.referrer;
            var program = options.program,
                resolver = options.resolver,
                macros = options.macros,
                asPartial = options.asPartial;
            var Builder = options.Builder;

            var b = new Builder(program, resolver, referrer, macros, layout, asPartial);
            b.startLabels();
            b.fetch(_vm.Register.s1);
            b.getComponentTagName(_vm.Register.s0);
            b.primitiveReference();
            b.dup();
            b.load(_vm.Register.s1);
            b.jumpUnless('BODY');
            b.fetch(_vm.Register.s1);
            b.putComponentOperations();
            b.openDynamicElement();
            b.didCreateElement(_vm.Register.s0);
            b.flushElement();
            b.label('BODY');
            b.invokeStaticBlock(blockFor(layout, this.options));
            b.fetch(_vm.Register.s1);
            b.jumpUnless('END');
            b.closeElement();
            b.label('END');
            b.load(_vm.Register.s1);
            b.stopLabels();
            var handle = b.commit(options.program.heap, layout.block.symbols.length);
            return handle;
        };

        return WrappedBuilder;
    }();

    function blockFor(layout, options) {
        var block = layout.block,
            referrer = layout.referrer;

        return new CompilableTemplateImpl(block.statements, layout, options, { referrer: referrer, parameters: _util.EMPTY_ARRAY });
    }

    var ComponentBuilder = function () {
        function ComponentBuilder(builder) {

            this.builder = builder;
        }

        ComponentBuilder.prototype.static = function (handle, args) {
            var params = args[0],
                hash = args[1],
                _default = args[2],
                inverse = args[3],
                capabilities,
                layout;
            var builder = this.builder;
            var resolver = builder.resolver;

            if (handle !== null) {
                capabilities = resolver.getCapabilities(handle);

                if (capabilities.dynamicLayout === false) {
                    layout = resolver.getLayout(handle);

                    builder.pushComponentDefinition(handle);
                    builder.invokeStaticComponent(capabilities, layout, null, params, hash, false, _default, inverse);
                } else {
                    builder.pushComponentDefinition(handle);
                    builder.invokeComponent(null, params, hash, false, _default, inverse);
                }
            }
        };

        return ComponentBuilder;
    }();

    var Labels = function () {
        function Labels() {

            this.labels = (0, _util.dict)();
            this.targets = [];
        }

        Labels.prototype.label = function (name, index) {
            this.labels[name] = index;
        };

        Labels.prototype.target = function (at, _target) {
            this.targets.push({ at: at, target: _target });
        };

        Labels.prototype.patch = function (encoder$$1) {
            var targets = this.targets,
                labels = this.labels,
                i,
                _targets$i,
                at,
                target,
                address;

            for (i = 0; i < targets.length; i++) {
                _targets$i = targets[i], at = _targets$i.at, target = _targets$i.target;
                address = labels[target] - at;

                encoder$$1.patch(at, address);
            }
        };

        return Labels;
    }();

    var SimpleOpcodeBuilder = function () {
        function SimpleOpcodeBuilder() {

            this.encoder = new _encoder.InstructionEncoder([]);
        }

        SimpleOpcodeBuilder.prototype.push = function (name) {
            switch (arguments.length) {
                case 1:
                    return this.encoder.encode(name, 0);
                case 2:
                    return this.encoder.encode(name, 0, arguments[1]);
                case 3:
                    return this.encoder.encode(name, 0, arguments[1], arguments[2]);
                default:
                    return this.encoder.encode(name, 0, arguments[1], arguments[2], arguments[3]);
            }
        };

        SimpleOpcodeBuilder.prototype.pushMachine = function (name) {
            switch (arguments.length) {
                case 1:
                    return this.encoder.encode(name, 1024 /* MACHINE_MASK */);
                case 2:
                    return this.encoder.encode(name, 1024 /* MACHINE_MASK */, arguments[1]);
                case 3:
                    return this.encoder.encode(name, 1024 /* MACHINE_MASK */, arguments[1], arguments[2]);
                default:
                    return this.encoder.encode(name, 1024 /* MACHINE_MASK */, arguments[1], arguments[2], arguments[3]);
            }
        };

        SimpleOpcodeBuilder.prototype.commit = function (heap, scopeSize) {
            this.pushMachine(20 /* Return */);
            var buffer = this.encoder.buffer,
                i,
                value;

            // TODO: change the whole malloc API and do something more efficient
            var handle = heap.malloc();
            for (i = 0; i < buffer.length; i++) {
                value = buffer[i];

                typeof value === 'function' ? heap.pushPlaceholder(value) : heap.push(value);
            }
            heap.finishMalloc(handle, scopeSize);
            return handle;
        };

        SimpleOpcodeBuilder.prototype.reserve = function (name) {
            this.encoder.encode(name, 0, -1);
        };

        SimpleOpcodeBuilder.prototype.reserveMachine = function (name) {
            this.encoder.encode(name, 1024 /* MACHINE_MASK */, -1);
        };

        SimpleOpcodeBuilder.prototype.main = function () {
            this.push(56 /* Main */, _vm.Register.s0);
            this.invokePreparedComponent(false);
        };

        SimpleOpcodeBuilder.prototype.dynamicContent = function () {
            this.push(24 /* DynamicContent */);
        };

        SimpleOpcodeBuilder.prototype.beginComponentTransaction = function () {
            this.push(75 /* BeginComponentTransaction */);
        };

        SimpleOpcodeBuilder.prototype.commitComponentTransaction = function () {
            this.push(76 /* CommitComponentTransaction */);
        };

        SimpleOpcodeBuilder.prototype.pushDynamicScope = function () {
            this.push(36 /* PushDynamicScope */);
        };

        SimpleOpcodeBuilder.prototype.popDynamicScope = function () {
            this.push(37 /* PopDynamicScope */);
        };

        SimpleOpcodeBuilder.prototype.pushRemoteElement = function () {
            this.push(33 /* PushRemoteElement */);
        };

        SimpleOpcodeBuilder.prototype.popRemoteElement = function () {
            this.push(34 /* PopRemoteElement */);
        };

        SimpleOpcodeBuilder.prototype.pushRootScope = function (symbols, bindCallerScope) {
            this.push(17 /* RootScope */, symbols, bindCallerScope ? 1 : 0);
        };

        SimpleOpcodeBuilder.prototype.pushChildScope = function () {
            this.push(18 /* ChildScope */);
        };

        SimpleOpcodeBuilder.prototype.popScope = function () {
            this.push(19 /* PopScope */);
        };

        SimpleOpcodeBuilder.prototype.prepareArgs = function (state) {
            this.push(65 /* PrepareArgs */, state);
        };

        SimpleOpcodeBuilder.prototype.createComponent = function (state, hasDefault) {
            this.push(67 /* CreateComponent */, hasDefault | 0, state);
        };

        SimpleOpcodeBuilder.prototype.registerComponentDestructor = function (state) {
            this.push(68 /* RegisterComponentDestructor */, state);
        };

        SimpleOpcodeBuilder.prototype.putComponentOperations = function () {
            this.push(69 /* PutComponentOperations */);
        };

        SimpleOpcodeBuilder.prototype.getComponentSelf = function (state) {
            this.push(70 /* GetComponentSelf */, state);
        };

        SimpleOpcodeBuilder.prototype.getComponentTagName = function (state) {
            this.push(71 /* GetComponentTagName */, state);
        };

        SimpleOpcodeBuilder.prototype.getComponentLayout = function (state) {
            this.push(72 /* GetComponentLayout */, state);
        };

        SimpleOpcodeBuilder.prototype.invokeComponentLayout = function (state) {
            this.push(74 /* InvokeComponentLayout */, state);
        };

        SimpleOpcodeBuilder.prototype.didCreateElement = function (state) {
            this.push(77 /* DidCreateElement */, state);
        };

        SimpleOpcodeBuilder.prototype.didRenderLayout = function (state) {
            this.push(78 /* DidRenderLayout */, state);
        };

        SimpleOpcodeBuilder.prototype.pushFrame = function () {
            this.pushMachine(47 /* PushFrame */);
        };

        SimpleOpcodeBuilder.prototype.popFrame = function () {
            this.pushMachine(48 /* PopFrame */);
        };

        SimpleOpcodeBuilder.prototype.invokeVirtual = function () {
            this.pushMachine(41 /* InvokeVirtual */);
        };

        SimpleOpcodeBuilder.prototype.invokeYield = function () {
            this.push(43 /* InvokeYield */);
        };

        SimpleOpcodeBuilder.prototype.toBoolean = function () {
            this.push(51 /* ToBoolean */);
        };

        SimpleOpcodeBuilder.prototype.invokePreparedComponent = function (hasBlock) {
            var populateLayout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            this.beginComponentTransaction();
            this.pushDynamicScope();
            this.createComponent(_vm.Register.s0, hasBlock);
            // this has to run after createComponent to allow
            // for late-bound layouts, but a caller is free
            // to populate the layout earlier if it wants to
            // and do nothing here.
            if (populateLayout) populateLayout();
            this.registerComponentDestructor(_vm.Register.s0);
            this.getComponentSelf(_vm.Register.s0);
            this.invokeComponentLayout(_vm.Register.s0);
            this.didRenderLayout(_vm.Register.s0);
            this.popFrame();
            this.popScope();
            this.popDynamicScope();
            this.commitComponentTransaction();
        };

        (0, _emberBabel.createClass)(SimpleOpcodeBuilder, [{
            key: 'pos',
            get: function () {
                return this.encoder.typePos;
            }
        }, {
            key: 'nextPos',
            get: function () {
                return this.encoder.size;
            }
        }]);
        return SimpleOpcodeBuilder;
    }();

    var OpcodeBuilder = function (_SimpleOpcodeBuilder) {
        (0, _emberBabel.inherits)(OpcodeBuilder, _SimpleOpcodeBuilder);

        function OpcodeBuilder(program, resolver, referrer, macros, containingLayout, asPartial, stdLib) {

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _SimpleOpcodeBuilder.call(this));

            _this.program = program;
            _this.resolver = resolver;
            _this.referrer = referrer;
            _this.macros = macros;
            _this.containingLayout = containingLayout;
            _this.asPartial = asPartial;
            _this.stdLib = stdLib;
            _this.component = new ComponentBuilder(_this);
            _this.expressionCompiler = expressionCompiler();
            _this.labelsStack = new _util.Stack();
            _this.isComponentAttrs = false;
            _this.constants = program.constants;
            return _this;
        }

        OpcodeBuilder.prototype.label = function (name) {
            this.labels.label(name, this.nextPos);
        };

        OpcodeBuilder.prototype.setComponentAttrs = function (enabled) {
            this.isComponentAttrs = enabled;
        };

        OpcodeBuilder.prototype.expr = function (expression) {
            if (Array.isArray(expression)) {
                this.expressionCompiler.compile(expression, this);
            } else {
                this.pushPrimitiveReference(expression);
            }
        };

        OpcodeBuilder.prototype.pushArgs = function (names, flags) {
            var serialized = this.constants.stringArray(names);
            this.push(63 /* PushArgs */, serialized, flags);
        };

        OpcodeBuilder.prototype.startLabels = function () {
            this.labelsStack.push(new Labels());
        };

        OpcodeBuilder.prototype.stopLabels = function () {
            var label = this.labelsStack.pop();
            label.patch(this.encoder);
        };

        OpcodeBuilder.prototype.pushComponentDefinition = function (handle) {
            this.push(59 /* PushComponentDefinition */, this.constants.handle(handle));
        };

        OpcodeBuilder.prototype.pushCurriedComponent = function () {
            this.push(61 /* PushCurriedComponent */);
        };

        OpcodeBuilder.prototype.pushDynamicComponentInstance = function () {
            this.push(60 /* PushDynamicComponentInstance */);
        };

        OpcodeBuilder.prototype.resolveDynamicComponent = function (referrer) {
            this.push(62 /* ResolveDynamicComponent */, this.constants.serializable(referrer));
        };

        OpcodeBuilder.prototype.staticComponentHelper = function (tag, hash, template) {
            var handle = this.resolver.lookupComponentDefinition(tag, this.referrer),
                capabilities,
                i,
                layout;
            if (handle) {
                capabilities = this.resolver.getCapabilities(handle);

                if (capabilities.dynamicLayout === false) {
                    if (hash) {
                        for (i = 0; i < hash.length; i = i + 2) {
                            hash[i][0] = '@' + hash[i][0];
                        }
                    }
                    layout = this.resolver.getLayout(handle);

                    this.pushComponentDefinition(handle);
                    this.invokeStaticComponent(capabilities, layout, null, null, hash, false, template && template);
                    return true;
                }
            }
            return false;
        };

        OpcodeBuilder.prototype.invokePartial = function (referrer, symbols, evalInfo) {
            var _meta = this.constants.serializable(referrer);
            var _symbols = this.constants.stringArray(symbols);
            var _evalInfo = this.constants.array(evalInfo);
            this.push(79 /* InvokePartial */, _meta, _symbols, _evalInfo);
        };

        OpcodeBuilder.prototype.resolveMaybeLocal = function (name) {
            this.push(80 /* ResolveMaybeLocal */, this.string(name));
        };

        OpcodeBuilder.prototype.debugger = function (symbols, evalInfo) {
            this.push(81 /* Debugger */, this.constants.stringArray(symbols), this.constants.array(evalInfo));
        };

        OpcodeBuilder.prototype.text = function (_text) {
            this.push(22 /* Text */, this.constants.string(_text));
        };

        OpcodeBuilder.prototype.openPrimitiveElement = function (tag) {
            this.push(25 /* OpenElement */, this.constants.string(tag));
        };

        OpcodeBuilder.prototype.openDynamicElement = function () {
            this.push(26 /* OpenDynamicElement */);
        };

        OpcodeBuilder.prototype.flushElement = function () {
            this.push(30 /* FlushElement */);
        };

        OpcodeBuilder.prototype.closeElement = function () {
            this.push(31 /* CloseElement */);
        };

        OpcodeBuilder.prototype.staticAttr = function (_name, _namespace, _value) {
            var name = this.constants.string(_name),
                value;
            var namespace = _namespace ? this.constants.string(_namespace) : 0;
            if (this.isComponentAttrs) {
                this.pushPrimitiveReference(_value);
                this.push(29 /* ComponentAttr */, name, 1, namespace);
            } else {
                value = this.constants.string(_value);

                this.push(27 /* StaticAttr */, name, value, namespace);
            }
        };

        OpcodeBuilder.prototype.dynamicAttr = function (_name, _namespace, trusting) {
            var name = this.constants.string(_name);
            var namespace = _namespace ? this.constants.string(_namespace) : 0;
            if (this.isComponentAttrs) {
                this.push(29 /* ComponentAttr */, name, trusting === true ? 1 : 0, namespace);
            } else {
                this.push(28 /* DynamicAttr */, name, trusting === true ? 1 : 0, namespace);
            }
        };

        OpcodeBuilder.prototype.comment = function (_comment) {
            var comment = this.constants.string(_comment);
            this.push(23 /* Comment */, comment);
        };

        OpcodeBuilder.prototype.modifier = function (locator, params, hash) {
            this.pushFrame();
            this.compileArgs(params, hash, null, true);
            this.push(32 /* Modifier */, this.constants.handle(locator));
            this.popFrame();
        };

        OpcodeBuilder.prototype.putIterator = function () {
            this.push(54 /* PutIterator */);
        };

        OpcodeBuilder.prototype.enterList = function (start) {
            this.reserve(52 /* EnterList */);
            this.labels.target(this.pos, start);
        };

        OpcodeBuilder.prototype.exitList = function () {
            this.push(53 /* ExitList */);
        };

        OpcodeBuilder.prototype.iterate = function (breaks) {
            this.reserve(55 /* Iterate */);
            this.labels.target(this.pos, breaks);
        };

        OpcodeBuilder.prototype.setVariable = function (symbol) {
            this.push(2 /* SetVariable */, symbol);
        };

        OpcodeBuilder.prototype.setBlock = function (symbol) {
            this.push(3 /* SetBlock */, symbol);
        };

        OpcodeBuilder.prototype.getVariable = function (symbol) {
            this.push(4 /* GetVariable */, symbol);
        };

        OpcodeBuilder.prototype.getProperty = function (key) {
            this.push(5 /* GetProperty */, this.string(key));
        };

        OpcodeBuilder.prototype.getBlock = function (symbol) {
            this.push(6 /* GetBlock */, symbol);
        };

        OpcodeBuilder.prototype.hasBlock = function (symbol) {
            this.push(7 /* HasBlock */, symbol);
        };

        OpcodeBuilder.prototype.hasBlockParams = function (to) {
            this.getBlock(to);
            this.resolveBlock();
            this.push(8 /* HasBlockParams */);
        };

        OpcodeBuilder.prototype.concat = function (size) {
            this.push(9 /* Concat */, size);
        };

        OpcodeBuilder.prototype.load = function (register) {
            this.push(15 /* Load */, register);
        };

        OpcodeBuilder.prototype.fetch = function (register) {
            this.push(16 /* Fetch */, register);
        };

        OpcodeBuilder.prototype.dup = function () {
            var register = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _vm.Register.sp;
            var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

            return this.push(13 /* Dup */, register, offset);
        };

        OpcodeBuilder.prototype.pop = function () {
            var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

            return this.push(14 /* Pop */, count);
        };

        OpcodeBuilder.prototype.returnTo = function (label) {
            this.reserveMachine(21 /* ReturnTo */);
            this.labels.target(this.pos, label);
        };

        OpcodeBuilder.prototype.primitive = function (_primitive) {
            var type = 0;
            var primitive = void 0;
            switch (typeof _primitive) {
                case 'number':
                    if (_primitive % 1 === 0) {
                        if (_primitive > -1) {
                            primitive = _primitive;
                        } else {
                            primitive = this.constants.number(_primitive);
                            type = 4 /* NEGATIVE */;
                        }
                    } else {
                        primitive = this.constants.number(_primitive);
                        type = 1 /* FLOAT */;
                    }
                    break;
                case 'string':
                    primitive = this.string(_primitive);
                    type = 2 /* STRING */;
                    break;
                case 'boolean':
                    primitive = _primitive | 0;
                    type = 3 /* BOOLEAN_OR_VOID */;
                    break;
                case 'object':
                    // assume null
                    primitive = 2;
                    type = 3 /* BOOLEAN_OR_VOID */;
                    break;
                case 'undefined':
                    primitive = 3;
                    type = 3 /* BOOLEAN_OR_VOID */;
                    break;
                default:
                    throw new Error('Invalid primitive passed to pushPrimitive');
            }
            var immediate = this.sizeImmediate(primitive << 3 | type, primitive);
            this.push(11 /* Primitive */, immediate);
        };

        OpcodeBuilder.prototype.sizeImmediate = function (shifted, primitive) {
            if (shifted >= 65535 /* MAX_SIZE */ || shifted < 0) {
                return this.constants.number(primitive) << 3 | 5 /* BIG_NUM */;
            }
            return shifted;
        };

        OpcodeBuilder.prototype.pushPrimitiveReference = function (primitive) {
            this.primitive(primitive);
            this.primitiveReference();
        };

        OpcodeBuilder.prototype.primitiveReference = function () {
            this.push(12 /* PrimitiveReference */);
        };

        OpcodeBuilder.prototype.helper = function (_helper, params, hash) {
            this.pushFrame();
            this.compileArgs(params, hash, null, true);
            this.push(1 /* Helper */, this.constants.handle(_helper));
            this.popFrame();
            this.fetch(_vm.Register.v0);
        };

        OpcodeBuilder.prototype.bindDynamicScope = function (_names) {
            this.push(35 /* BindDynamicScope */, this.names(_names));
        };

        OpcodeBuilder.prototype.enter = function (args) {
            this.push(49 /* Enter */, args);
        };

        OpcodeBuilder.prototype.exit = function () {
            this.push(50 /* Exit */);
        };

        OpcodeBuilder.prototype.return = function () {
            this.pushMachine(20 /* Return */);
        };

        OpcodeBuilder.prototype.jump = function (target) {
            this.reserveMachine(44 /* Jump */);
            this.labels.target(this.pos, target);
        };

        OpcodeBuilder.prototype.jumpIf = function (target) {
            this.reserve(45 /* JumpIf */);
            this.labels.target(this.pos, target);
        };

        OpcodeBuilder.prototype.jumpUnless = function (target) {
            this.reserve(46 /* JumpUnless */);
            this.labels.target(this.pos, target);
        };

        OpcodeBuilder.prototype.string = function (_string) {
            return this.constants.string(_string);
        };

        OpcodeBuilder.prototype.names = function (_names) {
            var names = [],
                i,
                n;
            for (i = 0; i < _names.length; i++) {
                n = _names[i];

                names[i] = this.constants.string(n);
            }
            return this.constants.array(names);
        };

        OpcodeBuilder.prototype.symbols = function (_symbols2) {
            return this.constants.array(_symbols2);
        };

        OpcodeBuilder.prototype.inlineBlock = function (block) {
            var parameters = block.parameters,
                statements = block.statements;

            var symbolTable = { parameters: parameters, referrer: this.containingLayout.referrer };
            var options = {
                program: this.program,
                macros: this.macros,
                Builder: this.constructor,
                resolver: this.resolver,
                asPartial: this.asPartial,
                referrer: this.referrer
            };
            return new CompilableTemplateImpl(statements, this.containingLayout, options, symbolTable);
        };

        OpcodeBuilder.prototype.evalSymbols = function () {
            var block = this.containingLayout.block;

            return block.hasEval ? block.symbols : null;
        };

        OpcodeBuilder.prototype.compileParams = function (params) {
            var i;

            if (!params) return 0;
            for (i = 0; i < params.length; i++) {
                this.expr(params[i]);
            }
            return params.length;
        };

        OpcodeBuilder.prototype.compileArgs = function (params, hash, blocks, synthetic) {
            if (blocks) {
                this.pushYieldableBlock(blocks.main);
                this.pushYieldableBlock(blocks.else);
                this.pushYieldableBlock(blocks.attrs);
            }
            var count = this.compileParams(params),
                val,
                i;
            var flags = count << 4;
            if (synthetic) flags |= 8;
            if (blocks) {
                flags |= 7;
            }
            var names = _util.EMPTY_ARRAY;
            if (hash) {
                names = hash[0];
                val = hash[1];

                for (i = 0; i < val.length; i++) {
                    this.expr(val[i]);
                }
            }
            this.pushArgs(names, flags);
        };

        OpcodeBuilder.prototype.invokeStaticBlock = function (block) {
            var callerCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0,
                i;
            var parameters = block.symbolTable.parameters;

            var calleeCount = parameters.length;
            var count = Math.min(callerCount, calleeCount);
            this.pushFrame();
            if (count) {
                this.pushChildScope();
                for (i = 0; i < count; i++) {
                    this.dup(_vm.Register.fp, callerCount - i);
                    this.setVariable(parameters[i]);
                }
            }
            this.pushBlock(block);
            this.resolveBlock();
            this.invokeVirtual();
            if (count) {
                this.popScope();
            }
            this.popFrame();
        };

        OpcodeBuilder.prototype.builtInGuardedAppend = function () {
            this.dup();
            this.startLabels();
            this.isComponent();
            this.enter(2);
            this.jumpUnless('ELSE');
            this.pushCurriedComponent();
            this.pushDynamicComponentInstance();
            this.invokeComponent(null, null, null, false, null, null);
            this.exit();
            this.return();
            this.label('ELSE');
            this.dynamicContent();
            this.exit();
            this.return();
            this.stopLabels();
        };

        OpcodeBuilder.prototype.guardedAppend = function (expression, trusting) {
            this.startLabels();
            this.pushFrame();
            this.returnTo('END');
            if (this.stdLib) {
                this.primitive(!!trusting);
                this.load(_vm.Register.t0);
                this.expr(expression);
                this.primitive(this.stdLib.guardedAppend);
                this.invokeVirtual();
            } else {
                this.expr(expression);
                this.dup();
                this.isComponent();
                this.enter(2);
                this.jumpUnless('ELSE');
                this.pushCurriedComponent();
                this.pushDynamicComponentInstance();
                this.invokeComponent(null, null, null, false, null, null);
                this.exit();
                this.return();
                this.label('ELSE');
                this.primitive(!!trusting);
                this.load(_vm.Register.t0);
                this.dynamicContent();
                this.exit();
                this.return();
            }
            this.label('END');
            this.popFrame();
            this.stopLabels();
        };

        OpcodeBuilder.prototype.yield = function (to, params) {
            this.compileArgs(params, null, null, false);
            this.getBlock(to);
            this.resolveBlock();
            this.invokeYield();
            this.popScope();
            this.popFrame();
        };

        OpcodeBuilder.prototype.populateLayout = function (state) {
            this.push(73 /* PopulateLayout */, state);
        };

        OpcodeBuilder.prototype.invokeComponent = function (attrs, params, hash, synthetic, block) {
            var _this2 = this;

            var inverse = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
            var layout = arguments[6];

            this.fetch(_vm.Register.s0);
            this.dup(_vm.Register.sp, 1);
            this.load(_vm.Register.s0);
            this.pushFrame();

            this.compileArgs(params, hash, { main: block, else: inverse, attrs: attrs }, synthetic);
            this.prepareArgs(_vm.Register.s0);
            this.invokePreparedComponent(block !== null, function () {
                if (layout) {
                    _this2.pushSymbolTable(layout.symbolTable);
                    _this2.pushLayout(layout);
                    _this2.resolveLayout();
                } else {
                    _this2.getComponentLayout(_vm.Register.s0);
                }
                _this2.populateLayout(_vm.Register.s0);
            });
            this.load(_vm.Register.s0);
        };

        OpcodeBuilder.prototype.invokeStaticComponent = function (capabilities, layout, attrs, params, hash, synthetic, block) {
            var inverse = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : null,
                i,
                symbol,
                callerBlock,
                keys,
                values,
                lookupName,
                index,
                _i,
                _bindings$_i,
                _symbol,
                isBlock;
            var symbolTable = layout.symbolTable;

            var bailOut = symbolTable.hasEval || capabilities.prepareArgs;
            if (bailOut) {
                this.invokeComponent(attrs, params, hash, synthetic, block, inverse, layout);
                return;
            }
            this.fetch(_vm.Register.s0);
            this.dup(_vm.Register.sp, 1);
            this.load(_vm.Register.s0);
            var symbols = symbolTable.symbols;

            if (capabilities.createArgs) {
                this.pushFrame();
                this.compileArgs(null, hash, null, synthetic);
            }
            this.beginComponentTransaction();
            this.pushDynamicScope();
            this.createComponent(_vm.Register.s0, block !== null);
            if (capabilities.createArgs) {
                this.popFrame();
            }
            this.registerComponentDestructor(_vm.Register.s0);
            var bindings = [];
            this.getComponentSelf(_vm.Register.s0);
            bindings.push({ symbol: 0, isBlock: false });
            for (i = 0; i < symbols.length; i++) {
                symbol = symbols[i];

                switch (symbol.charAt(0)) {
                    case '&':
                        callerBlock = null;

                        if (symbol === '&default') {
                            callerBlock = block;
                        } else if (symbol === '&inverse') {
                            callerBlock = inverse;
                        } else if (symbol === ATTRS_BLOCK) {
                            callerBlock = attrs;
                        } else {
                            throw (0, _util.unreachable)();
                        }
                        if (callerBlock) {
                            this.pushYieldableBlock(callerBlock);
                            bindings.push({ symbol: i + 1, isBlock: true });
                        } else {
                            this.pushYieldableBlock(null);
                            bindings.push({ symbol: i + 1, isBlock: true });
                        }
                        break;
                    case '@':
                        if (!hash) {
                            break;
                        }
                        keys = hash[0], values = hash[1];
                        lookupName = symbol;

                        if (synthetic) {
                            lookupName = symbol.slice(1);
                        }
                        index = keys.indexOf(lookupName);

                        if (index !== -1) {
                            this.expr(values[index]);
                            bindings.push({ symbol: i + 1, isBlock: false });
                        }
                        break;
                }
            }
            this.pushRootScope(symbols.length + 1, !!(block || inverse || attrs));
            for (_i = bindings.length - 1; _i >= 0; _i--) {
                _bindings$_i = bindings[_i], _symbol = _bindings$_i.symbol, isBlock = _bindings$_i.isBlock;


                if (isBlock) {
                    this.setBlock(_symbol);
                } else {
                    this.setVariable(_symbol);
                }
            }
            this.pushFrame();
            this.invokeStatic(layout);
            this.didRenderLayout(_vm.Register.s0);
            this.popFrame();
            this.popScope();
            this.popDynamicScope();
            this.commitComponentTransaction();
            this.load(_vm.Register.s0);
        };

        OpcodeBuilder.prototype.dynamicComponent = function (definition, /* TODO: attrs: Option<RawInlineBlock>, */params, hash, synthetic, block) {
            var inverse = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;

            this.startLabels();
            this.pushFrame();
            this.returnTo('END');
            this.expr(definition);
            this.dup();
            this.enter(2);
            this.jumpUnless('ELSE');
            this.resolveDynamicComponent(this.referrer);
            this.pushDynamicComponentInstance();
            this.invokeComponent(null, params, hash, synthetic, block, inverse);
            this.label('ELSE');
            this.exit();
            this.return();
            this.label('END');
            this.popFrame();
            this.stopLabels();
        };

        OpcodeBuilder.prototype.isComponent = function () {
            this.push(57 /* IsComponent */);
        };

        OpcodeBuilder.prototype.curryComponent = function (definition, /* TODO: attrs: Option<RawInlineBlock>, */params, hash, synthetic) {
            var referrer = this.referrer;
            this.pushFrame();
            this.compileArgs(params, hash, null, synthetic);
            this.push(66 /* CaptureArgs */);
            this.expr(definition);
            this.push(58 /* CurryComponent */, this.constants.serializable(referrer));
            this.popFrame();
            this.fetch(_vm.Register.v0);
        };

        OpcodeBuilder.prototype.pushSymbolTable = function (table) {
            var constant;

            if (table) {
                constant = this.constants.serializable(table);

                this.push(40 /* PushSymbolTable */, constant);
            } else {
                this.primitive(null);
            }
        };

        OpcodeBuilder.prototype.pushBlockScope = function () {
            this.push(39 /* PushBlockScope */);
        };

        OpcodeBuilder.prototype.pushYieldableBlock = function (block) {
            this.pushSymbolTable(block && block.symbolTable);
            this.pushBlockScope();
            this.pushBlock(block);
        };

        OpcodeBuilder.prototype.template = function (block) {
            if (!block) return null;
            return this.inlineBlock(block);
        };

        (0, _emberBabel.createClass)(OpcodeBuilder, [{
            key: 'labels',
            get: function () {
                return this.labelsStack.current;
            }
        }]);
        return OpcodeBuilder;
    }(SimpleOpcodeBuilder);

    var LazyOpcodeBuilder = function (_OpcodeBuilder) {
        (0, _emberBabel.inherits)(LazyOpcodeBuilder, _OpcodeBuilder);

        function LazyOpcodeBuilder() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _OpcodeBuilder.apply(this, arguments));
        }

        LazyOpcodeBuilder.prototype.pushBlock = function (block) {
            if (block) {
                this.pushOther(block);
            } else {
                this.primitive(null);
            }
        };

        LazyOpcodeBuilder.prototype.resolveBlock = function () {
            this.push(38 /* CompileBlock */);
        };

        LazyOpcodeBuilder.prototype.pushLayout = function (layout) {
            if (layout) {
                this.pushOther(layout);
            } else {
                this.primitive(null);
            }
        };

        LazyOpcodeBuilder.prototype.resolveLayout = function () {
            this.push(38 /* CompileBlock */);
        };

        LazyOpcodeBuilder.prototype.invokeStatic = function (compilable) {
            this.pushOther(compilable);
            this.push(38 /* CompileBlock */);
            this.pushMachine(41 /* InvokeVirtual */);
        };

        LazyOpcodeBuilder.prototype.pushOther = function (value) {
            this.push(10 /* Constant */, this.other(value));
        };

        LazyOpcodeBuilder.prototype.other = function (value) {
            return this.constants.other(value);
        };

        return LazyOpcodeBuilder;
    }(OpcodeBuilder);

    var EagerOpcodeBuilder = function (_OpcodeBuilder2) {
        (0, _emberBabel.inherits)(EagerOpcodeBuilder, _OpcodeBuilder2);

        function EagerOpcodeBuilder() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _OpcodeBuilder2.apply(this, arguments));
        }

        EagerOpcodeBuilder.prototype.pushBlock = function (block) {
            var handle = block ? block.compile(this.stdLib) : null;
            this.primitive(handle);
        };

        EagerOpcodeBuilder.prototype.resolveBlock = function () {};

        EagerOpcodeBuilder.prototype.pushLayout = function (layout) {
            if (layout) {
                this.primitive(layout.compile(this.stdLib));
            } else {
                this.primitive(null);
            }
        };

        EagerOpcodeBuilder.prototype.resolveLayout = function () {};

        EagerOpcodeBuilder.prototype.invokeStatic = function (compilable) {
            var _this5 = this;

            var handle = compilable.compile();
            // If the handle for the invoked component is not yet known (for example,
            // because this is a recursive invocation and we're still compiling), push a
            // function that will produce the correct handle when the heap is
            // serialized.
            if (handle === PLACEHOLDER_HANDLE$1) {
                this.pushMachine(42 /* InvokeStatic */, function () {
                    return compilable.compile(_this5.stdLib);
                });
            } else {
                this.pushMachine(42 /* InvokeStatic */, handle);
            }
        };

        return EagerOpcodeBuilder;
    }(OpcodeBuilder);

    var PartialDefinition = function () {
        function PartialDefinition(name, // for debugging
        template) {

            this.name = name;
            this.template = template;
        }

        PartialDefinition.prototype.getPartial = function () {
            var partial = this.template.asPartial();
            var handle = partial.compile();
            return { symbolTable: partial.symbolTable, handle: handle };
        };

        return PartialDefinition;
    }();

    var clientId = 0;


    var TemplateImpl = function () {
        function TemplateImpl(options, parsedLayout) {

            this.options = options;
            this.parsedLayout = parsedLayout;
            this.layout = null;
            this.partial = null;
            this.wrappedLayout = null;
            var block = parsedLayout.block;

            this.symbols = block.symbols;
            this.hasEval = block.hasEval;
            this.statements = block.statements;
            this.referrer = parsedLayout.referrer;
            this.id = parsedLayout.id || 'client-' + clientId++;
        }

        TemplateImpl.prototype.asLayout = function () {
            if (this.layout) return this.layout;
            return this.layout = compilable(this.parsedLayout, this.options, false);
        };

        TemplateImpl.prototype.asPartial = function () {
            if (this.partial) return this.partial;
            return this.partial = compilable(this.parsedLayout, this.options, true);
        };

        TemplateImpl.prototype.asWrappedLayout = function () {
            if (this.wrappedLayout) return this.wrappedLayout;
            var compileOptions = (0, _util.assign)({}, this.options, {
                asPartial: false,
                referrer: this.referrer
            });
            return this.wrappedLayout = new WrappedBuilder(compileOptions, this.parsedLayout);
        };

        return TemplateImpl;
    }();

    function compilable(layout, options, asPartial) {
        var block = layout.block,
            referrer = layout.referrer;
        var hasEval = block.hasEval,
            symbols = block.symbols;

        var compileOptions = (0, _util.assign)({}, options, { asPartial: asPartial, referrer: referrer });
        return new CompilableTemplateImpl(block.statements, layout, compileOptions, { referrer: referrer, hasEval: hasEval, symbols: symbols });
    }

    exports.ATTRS_BLOCK = ATTRS_BLOCK;
    exports.Macros = function () {

        var _populateBuiltins = populateBuiltins(),
            blocks = _populateBuiltins.blocks,
            inlines = _populateBuiltins.inlines;

        this.blocks = blocks;
        this.inlines = inlines;
    };
    exports.LazyOpcodeBuilder = LazyOpcodeBuilder;
    exports.EagerOpcodeBuilder = EagerOpcodeBuilder;
    exports.OpcodeBuilder = OpcodeBuilder;
    exports.SimpleOpcodeBuilder = SimpleOpcodeBuilder;
    exports.PartialDefinition = PartialDefinition;
    exports.templateFactory = function (_ref) {
        var templateId = _ref.id,
            meta = _ref.meta,
            block = _ref.block;

        var parsedBlock = void 0;
        var id = templateId || 'client-' + clientId++;

        return { id: id, meta: meta, create: function (options, envMeta) {
                var newMeta = envMeta ? (0, _util.assign)({}, envMeta, meta) : meta;
                if (!parsedBlock) {
                    parsedBlock = JSON.parse(block);
                }
                return new TemplateImpl(options, { id: id, block: parsedBlock, referrer: newMeta });
            } };
    };
    exports.CompilableTemplate = CompilableTemplateImpl;
    exports.debug = function (c, op) {
        for (_len = arguments.length, operands = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
            operands[_key - 2] = arguments[_key];
        }

        var metadata = null,
            _len,
            operands,
            _key;

        throw (0, _util.unreachable)('Missing Opcode Metadata for ' + op);

        var out = (0, _util.dict)();
    };
    exports.debugSlice = function () {};
    exports.logOpcode = function (type, params) {
        var out = type,
            args;
        if (params) {
            args = Object.keys(params).map(function (p) {
                return ' ' + p + '=' + json(params[p]);
            }).join('');

            out += args;
        }
        return '(' + out + ')';
    };
    exports.WrappedBuilder = WrappedBuilder;
    exports.PLACEHOLDER_HANDLE = -1;
});