define('ember-css-modules/helpers/local-class', ['exports', 'require'], function (exports, _require2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.localClass = localClass;
  function localClass(params, hash) {
    (false && !('from' in hash) && Ember.assert('No source specified to local-class lookup', 'from' in hash));

    if (!hash.from) {
      return '';
    }

    var styles = resolveSource(hash.from);
    var classes = (params[0] || '').split(/\s+/);

    return classes.map(function (style) {
      return styles[style];
    }).filter(Boolean).join(' ');
  }

  exports.default = Ember.Helper.helper(localClass);


  function resolveSource(source) {
    if (typeof source === 'string') {
      if (_require2.default.has(source)) {
        return (0, _require2.default)(source).default;
      } else {
        throw new Error('Unable to resolve local class names from ' + source + '; does the styles file exist?');
      }
    } else {
      return source;
    }
  }
});