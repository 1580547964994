define('ember-tumblr/models/tumblr-post-text', ['exports', 'ember-tumblr/models/tumblr-post', 'ember-data/attr'], function (exports, _tumblrPost, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tumblrPost.default.extend({
    title: (0, _attr.default)('string'),
    body: (0, _attr.default)('string')
  });
});