define('ember-data-github/serializers/github-pull', ['exports', 'ember-data-github/serializers/github', 'ember-data'], function (exports, _github, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _github.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      user: { embedded: 'always' }
    },

    normalize: function normalize(modelClass, resourceHash, prop) {
      resourceHash.user_avatar_url = resourceHash.user.avatar_url;
      resourceHash.user_login = resourceHash.user.login;

      return this._super(modelClass, resourceHash, prop);
    }
  });
});