define("ember-cli-fastboot/instance-initializers/clear-double-boot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clearHtml = clearHtml;
  _exports.default = void 0;

  // When using `ember serve` when fastboot addon is installed the application
  // output will already be rendered to the DOM when the actual JavaScript
  // loads. Ember does not automatically clear its `rootElement` so this
  // leads to the "double" applications being visible at once (only the
  // "bottom" one is running via JS and is interactive).
  //
  // This removes any pre-rendered ember-view elements, so that the booting
  // application will replace the pre-rendered output
  function clearHtml() {
    var current = document.getElementById('fastboot-body-start');

    if (current) {
      var endMarker = document.getElementById('fastboot-body-end');
      var parent = current.parentElement;
      var nextNode;

      do {
        nextNode = current.nextSibling;
        parent.removeChild(current);
        current = nextNode;
      } while (nextNode && nextNode !== endMarker);

      parent.removeChild(endMarker);
    }
  }

  var _default = {
    name: "clear-double-boot",
    initialize: function initialize(instance) {
      if (typeof FastBoot === 'undefined') {
        var originalDidCreateRootView = instance.didCreateRootView;

        instance.didCreateRootView = function () {
          clearHtml();
          originalDidCreateRootView.apply(instance, arguments);
        };
      }
    }
  };
  _exports.default = _default;
});