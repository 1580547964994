define("ember-disqus/components/disqus-comments", ["exports", "ember-disqus/utils/default-for", "ember-disqus/templates/components/disqus-comments", "ember-disqus/utils/load-disqus-api", "ember-disqus/utils/observers/set-on-window"], function (_exports, _defaultFor, _disqusComments, _loadDisqusApi, _setOnWindow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elementId: 'disqus_thread',
    classNames: ['disqus-comments'],

    /**
    Options that can be passed to identify the requested Disqus comment thread
    */
    categoryId: null,
    disqusUrl: null,
    identifier: null,
    title: null,
    layout: _disqusComments.default,

    /**
    Assert that all required properties have been passed to this component and, if required, load the `embed.js` script.
     The `#disqus_thread` element (this component) must be on the page before the `embed.js` script is loaded. Thus, we run this method on `didInsertElement`.
     @method didInsertElement
    */
    didInsertElement: function didInsertElement() {
      (false && !(this.get('identifier') || this.get('disqusUrl')) && Ember.assert('A Disqus identifier or disqusUrl must be set on the {{disqus-comments}} component', this.get('identifier') || this.get('disqusUrl')));

      if (!window.DISQUS) {
        (0, _loadDisqusApi.default)(this, 'embed');
      } else {
        this.reset();
      }
    },

    /**
    Adds ajax functionality to the comment thread. This method tells Disqus to load the comment thread with the given attributes.
     Usually you don't need to manually call this method - `ember-disqus` calls it in private methods.
     @method reset
    @param [identifier] the Disqus identifier to request the thread with. If not passed, will default to the component's current `identifier` property
    @param [title] the Disqus title to request the thread with. If not passed, will default to the component's current `title` property
    @param [disqus_url] the Disqus url to optionally request a thread from a different url if your site is whitelisted. If passed, `identifier` will be ignored.
    */
    reset: function reset(identifier, title, disqus_url) {
      Ember.run.debounce(this, function () {
        identifier = (0, _defaultFor.default)(identifier, this.get('identifier'));
        disqus_url = (0, _defaultFor.default)(disqus_url, this.get('disqusUrl'));
        title = (0, _defaultFor.default)(title, this.get('title'));
        /** @ref https://help.disqus.com/customer/portal/articles/472107-using-disqus-on-ajax-sites */

        window.DISQUS.reset({
          reload: true,
          config: function config() {
            this.page.identifier = !disqus_url && identifier || undefined;
            this.page.url = disqus_url || window.location.href;
            this.page.title = title || undefined;
          }
        });
      }, 100);
    },

    /**
    Disqus requires that all it's properties be set on the window. These methods observe the Disqus attributes and set them as required when they change.
    */
    _setCategoryId: (0, _setOnWindow.default)('categoryId', 'disqus_category_id'),
    _setDisqusUrl: (0, _setOnWindow.default)('disqusUrl', 'disqus_url'),
    _setIdentifier: (0, _setOnWindow.default)('identifier', 'disqus_identifier'),
    _setTitle: (0, _setOnWindow.default)('title', 'disqus_title'),

    /**
    Update the disqus comment thread when one of the thread attributes being passed to this component changes.
     @method _updateDisqusComments
    @private
     @todo - need a better way of identifying if DISQUS is already loaded here
    */
    _updateDisqusComments: Ember.observer('categoryId', 'disqusUrl', 'identifier', 'shortname', 'title', function () {
      if (window.DISQUS) {
        Ember.run.debounce(this, this.reset, 100);
      }
    })
  });

  _exports.default = _default;
});