define('ember-tumblr/serializers/tumblr-post', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    /**
     * Post Type
     *
     * @property type
     * @type {String}
     * @default ''
     */
    type: '',

    /**
     * Use Post Type to determine model name for serializing
     *
     * @method modelNameFromPayloadKey
     * @param {String} payloadKey The incoming payload key
     * @return {String} The modified key
     */
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var type = this.get('type');
      if (Ember.isPresent(type)) {
        return this._super(payloadKey.substring(0, payloadKey.length - 1) + '-' + type);
      }
      return this._super(payloadKey);
    },


    /**
     * Normalize the payload to match our models
     *
     * @method normalizePayload
     * @param {Object} payload The incoming payload
     * @return {Object} The modified payload
     * @public
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      if (payload.response && payload.response.posts) {
        var type = this.get('type') ? '-' + this.get('type') : '';
        var data = payload.response.posts.map(function (post) {
          post.date = _this.normalizeDate(post.date);
          delete post.tags;
          return {
            id: post.id,
            type: 'tumblr-post' + type,
            attributes: post
          };
        });
        if (requestType === 'queryRecord' || requestType === 'findRecord') {
          return { data: data[0] };
        }
        return { data: data };
      }
      return payload;
    },


    /**
     * Convert date from Tumblr API format to ISO string
     *
     * @method normalizeDate
     * @param {String} date The date string
     * @return {Date} normalized date object
     * @private
     */
    normalizeDate: function normalizeDate(date) {
      date = date.split(' ');
      date.pop(); // remove GMT from date string
      return date.join('T') + '.000Z';
    }
  });
});