enifed('ember-views/utils/lookup-component', ['exports', 'ember-babel', 'container', 'ember-environment'], function (exports, _emberBabel, _container, _emberEnvironment) {
  'use strict';

  exports.default = function (owner, name, options) {
    var componentLookup = owner.lookup('component-lookup:main'),
        localResult;

    var source = options && options.source;

    if (source) {
      localResult = lookupComponentPair(componentLookup, owner, name, options);


      if (localResult.component || localResult.layout) {
        return localResult;
      }
    }

    return lookupComponentPair(componentLookup, owner, name);
  };

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['component:-default'], ['component:-default']);

  function lookupComponentPair(componentLookup, owner, name, options) {

    var component = componentLookup.componentFor(name, owner, options);
    var layout = componentLookup.layoutFor(name, owner, options);

    var result = { layout: layout, component: component };

    if (!_emberEnvironment.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS && layout && !component) {
      result.component = owner.factoryFor((0, _container.privatize)(_templateObject));
    }

    return result;
  }
});