define("ember-disqus/utils/load-disqus-api", ["exports", "ember-disqus/utils/disqus-cache", "ember-disqus/utils/default-for"], function (_exports, _disqusCache, _defaultFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadDisqusApi;

  function loadDisqusApi(context, fileName) {
    var owner = Ember.getOwner(context),
        ENV = owner.resolveRegistration('config:environment');
    var documentIsReady, filePath, cachedValue, shortname, shouldLazyLoad;

    function tryCallback(retrievedFromCache) {
      if (Ember.typeOf(context.disqusCallback) === 'function') {
        context.disqusCallback(retrievedFromCache); // Ensure context
      }
    }

    (false && !(ENV.disqus && ENV.disqus.shortname) && Ember.assert('You must set a disqus.shortname option in your config/environment module', ENV.disqus && ENV.disqus.shortname));
    shortname = ENV.disqus.shortname;
    shouldLazyLoad = (0, _defaultFor.default)(ENV.disqus.lazyLoad, true);
    filePath = "//".concat(shortname, ".disqus.com/").concat(fileName, ".js");
    cachedValue = _disqusCache.default[filePath];
    /* Set the shortname property on the window */

    if (!window.disqus_shortname) {
      window.disqus_shortname = shortname;
    }

    documentIsReady = document.readyState === 'complete';
    /* Check to see is everything else in the app has loaded for lazy loading */

    if (cachedValue) {
      /* Allow the cache to store methods for testing purposes, etc */
      if (Ember.typeOf(cachedValue) === 'function') {
        cachedValue();
      }
      /* If window has the related Disqus property, don't load anything... */


      return tryCallback(true);
    } else if (!shouldLazyLoad || documentIsReady) {
      /* ... Else if we're ready to load the Disqus API, load it... */
      Ember.$.getScript(filePath).then(tryCallback(false));
      _disqusCache.default[filePath] = true; // So we know API has loaded
    } else {
      /* ... Else wait a small period and check again to see if the Ember app has fully loaded. */
      Ember.run.debounce(this, function () {
        loadDisqusApi(context, fileName);
      }, 200);
    }
  }
});