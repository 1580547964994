define('ember-tumblr/models/tumblr-post', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    blog_name: (0, _attr.default)('string'),
    post_url: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    timestamp: (0, _attr.default)('number'),
    date: (0, _attr.default)('date'),
    format: (0, _attr.default)('string'),
    reblog_key: (0, _attr.default)('string'),
    // tags
    bookmarklet: (0, _attr.default)('boolean'),
    mobile: (0, _attr.default)('boolean'),
    source_url: (0, _attr.default)('string'),
    source_title: (0, _attr.default)('string'),
    liked: (0, _attr.default)('boolean'),
    state: (0, _attr.default)('string'),
    total_posts: (0, _attr.default)('number')
  });
});