define('ember-router-scroll/services/router-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isFastBoot: Ember.computed(function () {
      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),

    scrollElement: 'window',

    init: function init() {
      this._super.apply(this, arguments);
      this._loadConfig();
      Ember.set(this, 'scrollMap', {});
      Ember.set(this, 'key', null);
    },
    update: function update() {
      var scrollElement = Ember.get(this, 'scrollElement');
      var scrollMap = Ember.get(this, 'scrollMap');
      var key = Ember.get(this, 'key');
      var x = void 0;
      var y = void 0;

      if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        if (Ember.get(this, 'isFastBoot')) {
          return;
        }

        var element = document.getElementById(scrollElement.substring(1));

        if (element) {
          x = element.scrollLeft;
          y = element.scrollTop;
        }
      }

      if (key && 'number' === Ember.typeOf(x) && 'number' === Ember.typeOf(y)) {
        Ember.set(scrollMap, key, { x: x, y: y });
      }
    },


    position: Ember.computed(function position() {
      var scrollMap = Ember.get(this, 'scrollMap');
      var stateUuid = Ember.get(window, 'history.state.uuid');

      Ember.set(this, 'key', stateUuid);
      var key = Ember.getWithDefault(this, 'key', '-1');

      return Ember.getWithDefault(scrollMap, key, { x: 0, y: 0 });
    }).volatile(),

    _loadConfig: function _loadConfig() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config && config.routerScroll && config.routerScroll.scrollElement) {
        var scrollElement = config.routerScroll.scrollElement;

        if ('string' === Ember.typeOf(scrollElement)) {
          Ember.set(this, 'scrollElement', scrollElement);
        }
      }
    }
  });
});