define('ember-css-modules/decorators', ['exports', '@ember-decorators/utils/decorator', '@ember-decorators/utils/collapse-proto'], function (exports, _decorator, _collapseProto) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.localClassName = undefined;
  exports.localClassNames = localClassNames;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function collapseAndMerge(prototype, property) {
    (0, _collapseProto.default)(prototype);

    for (var _len = arguments.length, items = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      items[_key - 2] = arguments[_key];
    }

    if (property in prototype) {
      var parentElements = prototype[property];
      items.unshift.apply(items, _toConsumableArray(parentElements));
    }

    prototype[property] = items;
  }

  /**
    Class decorator which specifies the local  class names to be applied to a
    component. This replaces the `localClassNames` property on components in the
    traditional Ember object model.
  
    ```js
    @localClassNames('a-static-class', 'another-static-class')
    export default class ClassNamesDemoComponent extends Component {}
    ```
  
    @param {...string} classNames - The list of local classes to be applied to the component
  */
  function localClassNames() {
    for (var _len2 = arguments.length, classNames = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      classNames[_key2] = arguments[_key2];
    }

    (false && !(classNames.every(function (className) {
      return typeof className === 'string';
    })) && Ember.assert('The @localClassNames decorator must be provided strings, received: ' + classNames, classNames.every(function (className) {
      return typeof className === 'string';
    })));


    return function (klass) {
      collapseAndMerge.apply(undefined, [klass.prototype, 'localClassNames'].concat(classNames));
      return klass;
    };
  }

  /**
    Decorator which indicates that the field or computed should be bound to the
    component local class names. This replaces `localClassNameBindings` by
    directly allowing you to specify which properties should be bound.
  
    ```js
    export default class ClassNameDemoComponent extends Component {
      // Adds 'bound-field' class, if true
      @localClassName boundField = true;
  
      // With provided true/false class names
      @localClassName('active', 'inactive') isActive = true;
    }
    ```
    @function
    @param {string} truthyName? - The local class to be applied if the value the
                                  field is truthy, defaults to the name of the
                                  field.
    @param {string} falsyName? - The class to be applied if the value of the field
                                 is falsy.
  */
  var localClassName = exports.localClassName = (0, _decorator.decoratorWithParams)(function (target, key, desc, params) {
    (false && !(params.length <= 2) && Ember.assert('The @localClassName decorator may take up to two parameters, the truthy class and falsy class for the class binding. Received: ' + params.length, params.length <= 2));
    (false && !(params.every(function (className) {
      return typeof className === 'string';
    })) && Ember.assert('The @localClassName decorator may only receive strings as parameters. Received: ' + params, params.every(function (className) {
      return typeof className === 'string';
    })));


    var binding = params.length > 0 ? key + ':' + params.join(':') : key;

    collapseAndMerge(target, 'localClassNameBindings', binding);

    if (desc) {
      // Decorated fields are currently not configurable in Babel for some reason, so ensure
      // that the field becomes configurable (else it messes with things)
      desc.configurable = true;
      desc.writable = true;

      // Babel provides a `null` initializer if one isn't set, but that can wind up
      // overwriting passed-in values if they're specified.
      if (desc.initializer === null) {
        desc.initializer = function () {
          return Ember.get(this, key);
        };
      }
    }

    return desc;
  });
});