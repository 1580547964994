define('ember-data-github/adapters/github-repository', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForFindRecord: function urlForFindRecord(id /*, modelName, snapshot*/) {
      var isInteger = /^\d+$/;
      var builtURL = this._super.apply(this, arguments);
      if (!isInteger.test(id)) {
        builtURL = builtURL.replace('repositories', 'repos');
      }
      return builtURL.replace('%2F', '/');
    },
    urlForQuery: function urlForQuery(query /*, modelName */) {
      var builtURL = this._super.apply(this, arguments);
      var user = query.user;


      delete query.user;

      return builtURL.replace('repositories', 'users/' + user + '/repos');
    }
  });
});