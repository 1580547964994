define('ember-interactivity/services/interactivity-tracking', ['exports', 'ember-interactivity/utils/config'], function (exports, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    trackComponent: function trackComponent() /* data */{
      if (this.isComponentInstrumentationDisabled()) {
        return;
      }
    },
    trackRoute: function trackRoute() /* data */{
      if (this.isRouteInstrumentationDisabled()) {
        return;
      }
    },
    trackError: function trackError() {},
    isComponentInstrumentationDisabled: function isComponentInstrumentationDisabled() {
      var options = (0, _config.default)(this);

      return options.instrumentation && options.instrumentation.disableComponents;
    },
    isRouteInstrumentationDisabled: function isRouteInstrumentationDisabled() {
      var options = (0, _config.default)(this);

      return options.instrumentation && options.instrumentation.disableRoutes;
    }
  });
});