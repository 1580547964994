define('ember-tumblr/adapters/tumblr-post', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({
    /**
     * Tumblr Application API Key
     * @type {string}
     * @default
     */
    apiKey: '',

    /**
     * Tumblr Blog URL
     * @type {string}
     * @default
     */
    blogUrl: '',

    /**
     * Tumblr API Hostname
     * @type {string}
     * @default
     */
    host: 'https://api.tumblr.com',

    /**
     * Post Type
     * @type {string}
     * @default
     */
    type: '',

    /**
     * Build namespace from blogURL
     * @returns {string} Tumblr API namespace with blogURL appended
     */
    namespace: Ember.computed('blogURL', function () {
      return 'v2/blog/' + this.get('blogUrl');
    }),

    /**
     * Build path from type
     * @method pathForType
     * @returns {string} API path with type string appended
     */
    pathForType: function pathForType() {
      var type = Ember.isPresent(this.get('type')) ? '/' + this.get('type') : '';
      return 'posts' + type;
    },


    /**
     * Build hash for AJAX call
     * Appends API key and sets data type
     * @method ajaxOptions
     * @returns {object} hash of AJAX options
     */
    ajaxOptions: function ajaxOptions() {
      var hash = this._super.apply(this, arguments);
      hash.data = hash.data || {};
      hash.data.api_key = this.get('apiKey');
      hash.dataType = 'jsonp';
      return hash;
    },


    /**
     * We need to make sure the cache is fresh;
     * if you load 1 post into the store,
     * it won't fetch all posts later
     * @method shouldReloadAll
     * @returns {boolean}
     */
    shouldReloadAll: function shouldReloadAll() {
      return true;
    }
  });
});