define('ember-interactivity/utils/timeline-marking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.markTimeline = markTimeline;
  var INITIALIZING_LABEL = exports.INITIALIZING_LABEL = 'Initializing';
  var INTERACTIVE_LABEL = exports.INTERACTIVE_LABEL = 'Interactive';

  var MEASURE_LABEL = 'Latency';

  function markTimeline(type, getTimelineLabel) {
    if (performance && performance.mark) {
      // TODO: Optional heimdall integration?
      performance.mark(getTimelineLabel(type));

      if (performance.measure && type === INTERACTIVE_LABEL) {
        performance.measure(getTimelineLabel(MEASURE_LABEL), getTimelineLabel(INITIALIZING_LABEL), getTimelineLabel(INTERACTIVE_LABEL));
      }
    }
  }
});