define("ember-disqus/components/disqus-comment-count", ["exports", "ember-disqus/templates/components/disqus-comment-count", "ember-disqus/utils/load-disqus-api"], function (_exports, _disqusCommentCount, _loadDisqusApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['identifier:data-disqus-identifier'],
    classNames: ['disqus-comment-count'],
    layout: _disqusCommentCount.default,
    removeNoun: false,
    tagName: 'span',

    /* Options */
    identifier: null,
    disqusCallback: function disqusCallback() {
      var disqusWidgets = window.DISQUSWIDGETS;

      if (disqusWidgets) {
        disqusWidgets.getCount(); // Required when transitioning between routes
      }
    },

    /**
    Assert that all required properties have been passed to this component and, if required, load the `count.js` script.
     @method didInsertElement
    */
    didInsertElement: function didInsertElement() {
      (false && !(this.get('identifier')) && Ember.assert('A Disqus identifier must be set on the {{disqus-comment-count}} component', this.get('identifier')));

      if (this.get('removeNoun')) {
        this.get('element').addEventListener('DOMSubtreeModified', function (event) {
          var target = event.target;
          /* Remove non-digit characters. For example '8 Comments' --> '8' */

          target.textContent = target.textContent.replace(/[\D]/g, '');
        });
      }

      (0, _loadDisqusApi.default)(this, 'count');
    }
  });

  _exports.default = _default;
});