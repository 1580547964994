define("ember-interactivity/utils/date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getTimeAsFloat = getTimeAsFloat;
  /**
   * Formats the current time as a float
   *
   * @method getTimeAsFloat
   *
   * @return {number} Current time
   */
  function getTimeAsFloat() {
    return new Date().getTime() / 1000;
  }
});