define("ember-disqus/utils/default-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultFor;

  function defaultFor(variable, defaultValue) {
    if (typeof variable !== 'undefined' && variable !== null) {
      return variable;
    } else {
      return defaultValue;
    }
  }
});