define("@ember-decorators/utils/-private/modifier-meta", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getModifierMeta = getModifierMeta;
  exports.getOrCreateModifierMeta = getOrCreateModifierMeta;
  var MODIFIER_META_MAP = new WeakMap();

  function getModifierMeta(target) {
    return MODIFIER_META_MAP.get(target);
  }

  function getOrCreateModifierMeta(target) {
    if (!MODIFIER_META_MAP.has(target)) {
      MODIFIER_META_MAP.set(target, {});
    }

    return MODIFIER_META_MAP.get(target);
  }
});