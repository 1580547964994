define("ember-disqus/utils/observers/set-on-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setOnWindow;

  function setOnWindow(dependentKey, propertyName) {
    return Ember.on('init', Ember.observer(dependentKey, function () {
      window[propertyName] = this.get(dependentKey);
    }));
  }
});