define('ember-data-github/serializers/github-tree', ['exports', 'ember-data-github/serializers/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    normalize: function normalize(modelClass, resourceHash, prop) {
      var blobItems = resourceHash.tree.filter(function (item) {
        return item.type === 'blob';
      });
      var treeItems = resourceHash.tree.filter(function (item) {
        return item.type === 'tree';
      });

      resourceHash.id = resourceHash.sha;
      resourceHash.files = blobItems.reduce(function (files, blob) {
        files[blob.path] = blob.sha;
        return files;
      }, {});
      resourceHash.directories = treeItems.reduce(function (files, tree) {
        files[tree.path] = tree.sha;
        return files;
      }, {});
      resourceHash.links = {
        blobs: blobItems.map(function (blob) {
          return blob.url;
        }),
        trees: treeItems.map(function (tree) {
          return tree.url;
        })
      };

      return this._super(modelClass, resourceHash, prop);
    }
  });
});