define('ember-tumblr/models/tumblr-post-photo', ['exports', 'ember-tumblr/models/tumblr-post', 'ember-data/attr'], function (exports, _tumblrPost, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tumblrPost.default.extend({
    caption: (0, _attr.default)('string'),

    photos: (0, _attr.default)(),
    photoCount: Ember.computed.alias('photos.length')
  });
});