define('ember-data-github/serializers/github-release', ['exports', 'ember-data-github/serializers/github', 'ember-data'], function (exports, _github, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _github.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      author: { embedded: 'always' }
    }
  });
});