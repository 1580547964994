define('ember-interactivity/utils/interactivity-subscriber', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _get = function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);

      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return getter.call(receiver);
    }
  };

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var InteractivitySubscriber = function () {
    /**
     * Creates the InteractivitySubscriber
     *
     * @method constructor
     *
     * @param {object} options - Single configuration parameter that expects the following attributes:
     *    {string} name - The name of the subscriber (used in testing) // TODO: Still needed?
     *    {function} isInteractive - Method for checking interactivity conditions as reports come in
     */
    function InteractivitySubscriber() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          name = _ref.name,
          isInteractive = _ref.isInteractive;

      _classCallCheck(this, InteractivitySubscriber);

      this.name = name;
      this._isInteractive = isInteractive;
      this._reporters = {};
      this._didReportInteractive = this._didReportInteractive.bind(this);
    }

    /**
     * Creates a promise to use for resolving interactivity conditions
     *
     * @method createPromise
     */


    _createClass(InteractivitySubscriber, [{
      key: 'createPromise',
      value: function createPromise() {
        var _this = this;

        this.promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this.resolve = resolve;
          _this.reject = reject;
        });
      }
    }, {
      key: 'checkInteractivity',
      value: function checkInteractivity() {
        if (this._isInteractive(this._didReportInteractive)) {
          this.resolve();
        }
      }
    }, {
      key: 'childBecameInteractive',
      value: function childBecameInteractive(reporter) {
        var latencyReportingName = reporter.get('_latencyReportingName');

        if (!this._reporters[latencyReportingName]) {
          this._reporters[latencyReportingName] = 1;
        } else {
          this._reporters[latencyReportingName]++;
        }
      }
    }, {
      key: 'childBecameNonInteractive',
      value: function childBecameNonInteractive(reporter) {
        var latencyReportingName = reporter.get('_latencyReportingName');

        if (this._reporters[latencyReportingName]) {
          this._reporters[latencyReportingName]--;
        }
      }
    }, {
      key: '_didReportInteractive',
      value: function _didReportInteractive(name, options) {
        if (options && options.count) {
          return this._reporters[name] === options.count;
        }

        return !!this._reporters[name];
      }
    }]);

    return InteractivitySubscriber;
  }();

  var ComponentInteractivitySubscriber = exports.ComponentInteractivitySubscriber = function (_InteractivitySubscri) {
    _inherits(ComponentInteractivitySubscriber, _InteractivitySubscri);

    /**
     * Creates the ComponentInteractivitySubscriber
     *
     * @method constructor
     *
     * @param {object} options - Single configuration parameter that expects the following attributes:
     *    {string} id - The id of the subscriber // TODO: Is this needed?
     *    {function} isInteractive - Method for checking interactivity conditions as reports come in
     */
    function ComponentInteractivitySubscriber(_ref2) {
      var id = _ref2.id,
          isInteractive = _ref2.isInteractive;

      _classCallCheck(this, ComponentInteractivitySubscriber);

      (false && !(typeof isInteractive === 'function') && Ember.assert('Every subscriber must provide an isInteractive method', typeof isInteractive === 'function'));

      var _this2 = _possibleConstructorReturn(this, (ComponentInteractivitySubscriber.__proto__ || Object.getPrototypeOf(ComponentInteractivitySubscriber)).apply(this, arguments));

      _this2.id = id;
      _this2.createPromise();

      // If the subscriber is already interactive, we should resolve immediately.
      _this2.checkInteractivity();
      return _this2;
    }

    return ComponentInteractivitySubscriber;
  }(InteractivitySubscriber);

  var RouteInteractivitySubscriber = exports.RouteInteractivitySubscriber = function (_InteractivitySubscri2) {
    _inherits(RouteInteractivitySubscriber, _InteractivitySubscri2);

    /**
     * Creates the RouteInteractivitySubscriber
     *
     * @method constructor
     */
    function RouteInteractivitySubscriber() {
      _classCallCheck(this, RouteInteractivitySubscriber);

      var _this3 = _possibleConstructorReturn(this, (RouteInteractivitySubscriber.__proto__ || Object.getPrototypeOf(RouteInteractivitySubscriber)).apply(this, arguments));

      _this3.isActive = false;
      return _this3;
    }

    /**
     * Make this the active route subscriber
     *
     * @method subscribe
     *
     * @param {object} options - Single configuration parameter that expects the following attributes:
     *    {string} name - The name of the subscriber (used in testing) // TODO: Still needed?
     *    {function} isInteractive - Method for checking interactivity conditions as reports come in
     */


    _createClass(RouteInteractivitySubscriber, [{
      key: 'subscribe',
      value: function subscribe(_ref3) {
        var name = _ref3.name,
            isInteractive = _ref3.isInteractive;

        this.isActive = true;
        this.name = name;
        this._isInteractive = isInteractive;
        this.createPromise();
      }

      /**
       * Unsubscribe this route
       *
       * @method unsubscribe
       */

    }, {
      key: 'unsubscribe',
      value: function unsubscribe() {
        this.isActive = false;
        this.name = null;
        this.promise = null;
        this.resolve = null;
        this.reject = null;
      }

      /**
       * Check interactivity if this is the active route
       *
       * @method checkInteractivity
       */

    }, {
      key: 'checkInteractivity',
      value: function checkInteractivity() {
        if (!this.isActive) {
          return;
        }

        _get(RouteInteractivitySubscriber.prototype.__proto__ || Object.getPrototypeOf(RouteInteractivitySubscriber.prototype), 'checkInteractivity', this).call(this);
      }
    }]);

    return RouteInteractivitySubscriber;
  }(InteractivitySubscriber);
});