define('ember-bug-widget/components/bug-widget', ['exports', 'ember-bug-widget/templates/components/bug-widget'], function (exports, _bugWidget) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        hide: true,
        text: 'Report A Bug',
        horizontal: 'right',
        vertical: 'bottom',
        bugIcon: 'bug',
        arrowLeftIcon: 'angle-double-left',
        arrowRightIcon: 'angle-double-right',
        layout: _bugWidget.default,

        hiddenClass: Ember.computed('hide', function () {
            if (this.get('hide')) {
                return 'hide';
            }

            return '';
        }),

        arrowIcon: Ember.computed('{hide,arrowLeftIcon,arrowRightIcon,horizontal}', function () {
            var hide = this.get('hide');
            var left = this.get('arrowLeftIcon');
            var right = this.get('arrowRightIcon');
            var horizontal = this.get('horizontal');

            if (horizontal.toLowerCase() === 'right') {
                return hide ? left : right;
            }

            return hide ? right : left;
        }),

        actions: {
            toggle: function toggle() {
                this.toggleProperty('hide');
            }
        }
    });
});