define('ember-is-visible/services/visibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    visible: true,
    lostVisibility: false,

    init: function init() {
      this._super.apply(this, arguments);
      this._handleDocumentVisibilityChange();

      document.addEventListener('visibilitychange', Ember.run.bind(this, '_handleDocumentVisibilityChange'));
    },
    _handleDocumentVisibilityChange: function _handleDocumentVisibilityChange() {
      if (this.isDestroyed) {
        return;
      }
      this.set('visible', !document.hidden);
      if (document.hidden) {
        this.set('lostVisibility', true);
      }
    }
  });
});