define('ember-data-github/serializers/github-repository-contents', ['exports', 'ember-data-github/serializers/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    extractId: function extractId(modelClass, resourceHash) {
      return resourceHash.url;
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'github-repository-contents';
    }
  });
});