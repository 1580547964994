define('ember-data-github/adapters/github', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var RESTAdapter = _emberData.default.RESTAdapter;
  exports.default = RESTAdapter.extend({

    session: Ember.inject.service('github-session'),

    host: 'https://api.github.com',

    headers: Ember.computed('session.githubAccessToken', function () {
      var headers = {};
      if (this.get('session.githubAccessToken')) {
        headers.Authorization = 'token ' + this.get('session.githubAccessToken');
      }
      return headers;
    }),

    pathForType: function pathForType(type) {
      return Ember.String.camelize((0, _emberInflector.pluralize)(type.replace('github', '')));
    },


    // Parse Link response header out into an object like:
    //   {
    //     first: 'https://api.github.com/resouce?page=1&per_page=5',
    //     next:  'https://api.github.com/resouce?page=3&per_page=5',
    //     prev:  'https://api.github.com/resouce?page=1&per_page=5',
    //     last:  'https://api.github.com/resouce?page=4&per_page=5',
    //   }
    //
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var linkHeader = headers.link || headers.Link;
      var result = this._super(status, headers, payload, requestData);
      if (Ember.isNone(linkHeader)) {
        return result;
      }

      var links = linkHeader.split(', ').reduce(function (memo, link) {
        var _link$split = link.split('; '),
            _link$split2 = _slicedToArray(_link$split, 2),
            url = _link$split2[0],
            rel = _link$split2[1];

        try {
          //eslint-disable-next-line no-useless-escape
          var _url$match = url.match(/<(.+)>/);

          var _url$match2 = _slicedToArray(_url$match, 2);

          url = _url$match2[1];

          var _rel$match = rel.match(/rel=\"(.+)\"/);

          var _rel$match2 = _slicedToArray(_rel$match, 2);

          rel = _rel$match2[1];
        } catch (error) {
          // Any error in parsing should not cause the application to error
          return;
        }

        memo[rel] = url;
        return memo;
      }, {});

      result.links = links;
      return result;
    }
  });
});