define('ember-data-github/adapters/github-compare', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      var repo = query.repo,
          base = query.base,
          head = query.head;

      delete query.repo;
      delete query.base;
      delete query.head;

      return this.get('host') + '/repos/' + repo + '/compare/' + base + '...' + head;
    }
  });
});