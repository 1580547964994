define('ember-interactivity/utils/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getInteractivityConfig;
  function getInteractivityConfig(scope) {
    var owner = Ember.getOwner(scope);
    if (owner) {
      var env = owner.resolveRegistration('config:environment');
      if (env) {
        return env.interactivity || {};
      }
    }

    return {};
  }
});