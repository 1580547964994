define('ember-data-github/adapters/github-branch', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this._super(id, modelName, snapshot).replace('branches', 'repos').replace(/%2F/g, '/');
    },
    urlForQuery: function urlForQuery(query) {
      var repo = query.repo;
      delete query.repo;

      return this.get('host') + '/repos/' + repo + '/branches';
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      var repo = query.repo,
          branch = query.branch;

      delete query.repo;
      delete query.branch;

      return this.get('host') + '/repos/' + repo + '/branches/' + branch;
    }
  });
});